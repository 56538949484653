import { newUUID } from '../utils/uuid'

export interface IChartSettings {
  queryConfigs: IQueryConfig[]
  displayConfig: IChartDisplayConfig
}

export interface IQueryConfig {
  id: string
  site: string
  node?: string
  source: string
  metric: string
  deviceType?: string
  useBuckets?: boolean
  bucketSize?: number
  bucketOp?: string
  style?: string
  axis?: string
}

export interface IQueryConfigLookup {
  [key: string]: IQueryConfig
}

export function DefaultQueryConfig() {
  return {
    id: newUUID(),
    site: '',
    source: 'gt-historical',
    metric: 'airTemp',
    style: 'line',
    axis: 'y'
  } as IQueryConfig
}

export function DefaultQueryConfigs() {
  return [DefaultQueryConfig()] as IQueryConfig[]
}

export interface IChartDisplayConfig {

  title?: string

  y1Max?: number
  y1Min?: number
  y2Max?: number
  y2Min?: number
}

export interface IChartDisplayConfigLookup {
  [key: string]: IChartDisplayConfig
}

export function DefaultChartDisplayConfig() {
  return {
    title: ''
  } as IChartDisplayConfig
}
