import { useState } from "react"

import { IChartDisplayConfig } from "../../../types/IQueryConfig"

import ExpandingPanel from "../../../components/Panels/ExpandingPanel"
import { CheckBoxWithTextInput } from "../../../components/CheckBox/CheckBoxWithTextInput"

import './YAxisConfig.css'

interface YAxisConfigProps {
  onChange: any
  chartDisplayConfig: IChartDisplayConfig
  useY1: boolean
  useY2: boolean
}

const isNumeric = (val: string): boolean => {
  return /^-?\d+$/.test(val)
}

const checkBoxLabel = 'Auto'
const inputPlaceholder = checkBoxLabel

const y1MaxFromConfig = (cfg: IChartDisplayConfig): string => {
  return cfg && 'y1Max' in cfg &&
  cfg.y1Max !== undefined ? cfg.y1Max.toString() : ''
}

const y1MinFromConfig = (cfg: IChartDisplayConfig): string => {
  return cfg && 'y1Min' in cfg &&
  cfg.y1Min !== undefined ? cfg.y1Min.toString() : ''
}

const y2MinFromConfig = (cfg: IChartDisplayConfig): string => {
  return cfg && 'y2Min' in cfg &&
  cfg.y2Min !== undefined ? cfg.y2Min.toString() : ''
}

const y2MaxFromConfig = (cfg: IChartDisplayConfig): string => {
  return cfg && 'y2Max' in cfg &&
  cfg.y2Max !== undefined ? cfg.y2Max.toString() : ''
}

export default function YAxisConfig(props: YAxisConfigProps) {

  const { chartDisplayConfig } = props

  const [y1Max] = useState(y1MaxFromConfig(chartDisplayConfig))
  const [y1Min] = useState(y1MinFromConfig(chartDisplayConfig))
  const [y2Max] = useState(y2MaxFromConfig(chartDisplayConfig))
  const [y2Min] = useState(y2MinFromConfig(chartDisplayConfig))

  const updateYAxisBound = (bound: string, val: string) => {
    val = val.replaceAll(' ', '')
    if (!val || !isNumeric(val)) {
      props.onChange(bound, undefined)
    } else {
      props.onChange(bound, parseInt(val))
    }
  }

  const updateY1Min = (val: string) => {
    updateYAxisBound('y1Min', val)
  }

  const updateY1Max = (val: string) => {
    updateYAxisBound('y1Max', val)
  }

  const updateY2Min = (val: string) => {
    updateYAxisBound('y2Min', val)
  }

  const updateY2Max = (val: string) => {
    updateYAxisBound('y2Max', val)
  }

  return (
    <ExpandingPanel
      title="Y Axis Config"
      className="panel-y-axis-config"
      openClassName="panel-y-axis-config-open"
      defaultIsOpen={false}
      key="12345"
    >
      <div className="y-axis-config-inner" key="123456">
        {props.useY1 &&
          <>
            <CheckBoxWithTextInput
              label="Y1 Max"
              checkBoxLabel={checkBoxLabel}
              placeholder={inputPlaceholder}
              defaultChecked={chartDisplayConfig.y1Max === undefined}
              defaultValue={y1Max}
              onChange={updateY1Max}
              labelClass="query-pane-row-label"
              inputEnabledWhenUnchecked={true}
             />
            <CheckBoxWithTextInput
              label="Y1 Min"
              checkBoxLabel={checkBoxLabel}
              placeholder={inputPlaceholder}
              defaultChecked={chartDisplayConfig.y1Min === undefined}
              defaultValue={y1Min}
              onChange={updateY1Min}
              labelClass="query-pane-row-label"
              inputEnabledWhenUnchecked={true}
            />
          </>
        }
        {props.useY2 &&
          <>
            <CheckBoxWithTextInput
              label="Y2 Max"
              checkBoxLabel={checkBoxLabel}
              placeholder={inputPlaceholder}
              defaultChecked={chartDisplayConfig.y2Max === undefined}
              defaultValue={y2Max}
              onChange={updateY2Max}
              labelClass="query-pane-row-label"
              inputEnabledWhenUnchecked={true}
            />
            <CheckBoxWithTextInput
              label="Y2 Min"
              checkBoxLabel={checkBoxLabel}
              placeholder={inputPlaceholder}
              defaultChecked={chartDisplayConfig.y2Min === undefined}
              defaultValue={y2Min}
              onChange={updateY2Min}
              labelClass="query-pane-row-label"
              inputEnabledWhenUnchecked={true}
            />
          </>
        }
      </div>
    </ExpandingPanel>
  )

}