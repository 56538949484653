import { useMemo } from 'react'
import { faChartArea, faTableCells } from '@fortawesome/free-solid-svg-icons'
import ButtonBasic from '../../components/Buttons/ButtonBasic'
import './GenerateSelectFormat.css'

export type ReportSelectedFunc = (format: string) => void

interface GenerateSelectFormatProps {
  formatSelectedFunc: ReportSelectedFunc
  className?: string
}

export function GenerateSelectFormat(props: GenerateSelectFormatProps) {

  const { className } = props

  const classes = useMemo(() => {
    const base = "generate-report-select-format"
    if (className) {
      return `${base} ${className}`
    }
    return base
  }, [className])

  return <div className={classes}>
    <div className="generate-report-select-format-label">
      Select a Report Format:
    </div>
    <div className="generate-report-select-format-pdf">
      <ButtonBasic
        label="PDF"
        icon={faChartArea}
        className="generate-report-select-format-button"
        onClick={() => {props.formatSelectedFunc('pdf')}}
      />
    </div>
    <div>
      <ButtonBasic
        label="CSV"
        icon={faTableCells}
        className="generate-report-select-format-button"
        onClick={() => {props.formatSelectedFunc('csv')}}
      />
    </div>
  </div>
}
