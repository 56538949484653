import { LabelValuePairs } from '../../types/generic'
import { GetLocalizedTextItem } from '../../localization/localization'

const lang = 'en-us'

export const reportCadences = [
  {value: 'daily', label: GetLocalizedTextItem(lang, 'runsDaily', 'upperAllFirst' ) },
  {value: 'weekly', label: GetLocalizedTextItem(lang, 'runsWeekly', 'upperAllFirst' ) },
  {value: 'monthly', label: GetLocalizedTextItem(lang, 'runsMonthly', 'upperAllFirst' ) },
] as LabelValuePairs

export const reportRanges = [
  // {value: 'snl', label: GetLocalizedTextItem(lang, 'seasonToDate', 'upperAllFirst' ) },
  {value: 'ytd', label: GetLocalizedTextItem(lang, 'yearToDate', 'upperAllFirst' ) },
  {value: 'mtd',  label: GetLocalizedTextItem(lang, 'monthToDate', 'upperAllFirst' ) },
  {value: 'lw', label: GetLocalizedTextItem(lang, 'previousWeekMon', 'upperAllFirst' ) },
  {value: 'l7d', label: GetLocalizedTextItem(lang, 'last7Days', 'upperAllFirst' ) },
  {value: 'lm', label: GetLocalizedTextItem(lang, 'previousMonth', 'upperAllFirst' ) },
  {value: 'ly', label: GetLocalizedTextItem(lang, 'previousYear', 'upperAllFirst' ) },
  {value: 'custom', label: GetLocalizedTextItem(lang, 'customTimeRange', 'upperAllFirst' ) },
] as LabelValuePairs

export const timeAggregations = [
  {value: "1800", label: `30 ${GetLocalizedTextItem(lang, 'minutes', 'upperAllFirst' )}`},
  {value: "3600", label: `1 ${GetLocalizedTextItem(lang, 'hour', 'upperAllFirst' )}`},
  {value: "43200",  label: `12 ${GetLocalizedTextItem(lang, 'hours', 'upperAllFirst' )}`},
  {value: "86400", label: `1 ${GetLocalizedTextItem(lang, 'day', 'upperAllFirst' )}`},
  {value: "604800", label: `1 ${GetLocalizedTextItem(lang, 'week', 'upperAllFirst' )}` },
  {value: "2592000", label: `1 ${GetLocalizedTextItem(lang, 'month', 'upperAllFirst' )}` }
] as LabelValuePairs

export const growerReportAggregations = [
  {value: 'customer', label: GetLocalizedTextItem(lang, 'customerLabel', 'upperAllFirst' ) },
] as LabelValuePairs

export const growerReportChartScopes = [
  {value: "customer", label: "Fewer Charts with More Lines" },
  {value: "site", label: "Normal" },
  {value: "node", label: "More Charts with Fewer Lines" }
] as LabelValuePairs

export const growerReportFilesScopes = [
  {value: "customer", label: "All Charts in One File" },
  {value: "site", label: "One File per-Site" },
  {value: "node", label: "One File per-Node" }
] as LabelValuePairs

export const csvReportFilesScopes = [
  {value: "customer", label: "All Data in One File" },
  {value: "site", label: "One File per-Site" },
  {value: "node", label: "One File per-Node" }
] as LabelValuePairs
