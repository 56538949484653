import { useContext, useMemo } from 'react'

import { PrefsContext } from '../../utils/prefs/PrefsContext'
import { SiteAccess } from '../../types/api'
import { newUUID } from "../../utils/uuid"
import { siteAndNodeOptions, SiteAndNodeOptions, SiteAndNodeOptionsInput } from './SiteAndNodeOptions'

import Select from '../Selects/Select'

import './SiteAndNodeSelector.css'

interface SiteAndNodeSelectorProps {
  className?: string
  labelClass?: string
  siteId?: string
  nodeId?: string
  onSiteChange?: any
  onNodeChange?: any
  hideLabels?: boolean
  hideSelectASite?: boolean
  showAllSitesOption?: boolean
  wrapperClass?: string
}

export default function SiteAndNodeSelector(props: SiteAndNodeSelectorProps) {
  const prefs = useContext(PrefsContext)

  const { siteId, hideLabels, nodeId, wrapperClass, showAllSitesOption, hideSelectASite } = props
  const { customerAccess } = prefs

  const allOptions = useMemo((): SiteAndNodeOptions => {
    return siteAndNodeOptions({
      customerAccess: customerAccess,
      showAllSitesOption: showAllSitesOption,
      showSelectSiteOption: hideSelectASite === undefined || hideSelectASite === false,
      selectedSite: siteId,
      showAllNodesOption: true,
    } as SiteAndNodeOptionsInput)
  }, [customerAccess, hideSelectASite, showAllSitesOption, siteId ])

  const siteLabel = useMemo(() => {
    if (hideLabels) {
      return undefined
    }
    return 'Site'
  }, [hideLabels])

  const nodeLabel = useMemo(() => {
    if (hideLabels) {
      return undefined
    }
    return 'Node'
  }, [hideLabels])

  const siteSelectKey = useMemo(() => {
    const out = {
      s: siteId,
      access: customerAccess,
      id: newUUID()
    }
    return out.id
  }, [customerAccess, siteId])

  const siteAccess = useMemo(() => {
    if (customerAccess && customerAccess.sites &&
      siteId && siteId in customerAccess.sites) {
      return customerAccess.sites[siteId]
    }
    return {} as SiteAccess
  }, [customerAccess, siteId])

  const nodeSelectKey = useMemo(() => {
    const out = {
      s: siteAccess,
      i: siteId,
      b: nodeId,
      id: newUUID()
    }
    return out.id
  }, [siteAccess, siteId, nodeId])

  const wrapperClasses = useMemo(() => {
    const base = ''
    if (wrapperClass) {
      return `${base} ${wrapperClass}`
    }
    return base
  }, [wrapperClass])

  return (
    <div className={wrapperClasses}>
      <Select
        label={siteLabel}
        labelClass={props.labelClass}
        className={`wide mb-2 ${props.className}`}
        onChange={props.onSiteChange}
        defaultValue={siteId}
        key={siteSelectKey}
        items={allOptions.sites}
      />
      <Select
        label={nodeLabel}
        labelClass={props.labelClass}
        className={`wide mb-2 ${props.className}`}
        disabled={!siteId}
        onChange={props.onNodeChange}
        defaultValue={props.nodeId}
        key={nodeSelectKey}
        items={allOptions.nodes}
      />
    </div>
  )
}
