import ModalBasic, { ButtonConfig } from '../../components/modals/ModalBasic'
import { Wordmark } from '../../components/svg/Wordmark'

import './LoginModal.css'
import LoginPanel from './LoginPanel'
import { useState } from 'react'

export function LoginHeader(): JSX.Element {
  return (
    <div className="login-header">
      <img
        alt="Agrology Logo Icon"
        src="./logo192.png"
        className="login-icon"
      />
      <Wordmark className="login-wordmark" />
    </div>
  )

}

export default function LoginModal() {
  const [buttons, setButtons] = useState([] as ButtonConfig[])
  return (
    <ModalBasic
      sz="sm"
      headerContent={LoginHeader}
      closeable={false}
      closeButtonClass="d-none"
      buttonConfigs={buttons}
      stackButtons={true}
    >
      <LoginPanel setButtonsFunc={setButtons} key={0} />
    </ModalBasic>
  )
}