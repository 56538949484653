import { useContext } from 'react'
import { PrefsContext } from '../../utils/prefs/PrefsContext'
import ContextMenu from '../../components/menus/ContextMenu'
import { MenuItem } from '../../components/menus/MenuItem'

import './SettingsMenu.css'
import { CloserFunc } from '../../types/generic'

interface SettingsMenuProps {
  closer: CloserFunc,
  posX: number
}

export function SettingsMenu(props: SettingsMenuProps) {

  const prefs = useContext(PrefsContext)

  const setOSThemeMode = () => {
    if (prefs.setThemeFunc) {
      prefs.setThemeFunc('os')
    }
  }

  const setDarkMode = () => {
    if (prefs.setThemeFunc) {
      prefs.setThemeFunc('darkMode')
    }
  }

  const setLightMode = () => {
    if (prefs.setThemeFunc) {
      prefs.setThemeFunc('lightMode')
    }
  }

  const setHighContrastMode = () => {
    if (prefs.setThemeFunc) {
      prefs.setThemeFunc('highContrastMode')
    }
  }

  const setMetric = () => {
    if (prefs.setUnitsFunc) {
      prefs.setUnitsFunc('metric')
    }
  }

  const setImperial = () => {
    if (prefs.setUnitsFunc) {
      prefs.setUnitsFunc('imperial')
    }
  }

  const setSiteUnits = () => {
    if (prefs.setUnitsFunc) {
      prefs.setUnitsFunc('site')
    }
  }

  return (
    <ContextMenu
      headerLabel="Settings"
      posX={props.posX}
      className="settings-menu"
      closerFunc={props.closer}
      items={
        [
          {
            label: 'Use Operating System Theme',
            onClick: setOSThemeMode,
            isChecked: localStorage.getItem('prefs.colorTheme') === 'os'
          },
          {
            label: 'Use Dark Theme',
            onClick: setDarkMode,
            isChecked: localStorage.getItem('prefs.colorTheme') === 'darkMode'
          },
          {
            label: 'Use Light Theme',
            onClick: setLightMode,
            isChecked: localStorage.getItem('prefs.colorTheme') === 'lightMode'
          },
          {
            label: 'Use High-Contrast Theme',
            onClick: setHighContrastMode,
            isChecked: localStorage.getItem('prefs.colorTheme') === 'highContrastMode'
          },
          {
            isSeparator: true
          },
          {
            label: "Use Site's Preferred Units",
            onClick: setSiteUnits,
            isChecked: prefs.units === 'site'
          },
          {
            label: "Use Metric Units",
            onClick: setMetric,
            isChecked: prefs.units === 'metric'
          },
          {
            label: "Use Imperial Units",
            onClick: setImperial,
            isChecked: prefs.units === 'imperial'
          },
        ] as MenuItem[]
      }
    />
  )
}
