import { useContext, useEffect, useMemo } from 'react'
import { PrefsContext } from '../../utils/prefs/PrefsContext'
import { GetLocalizedTextItem } from '../../localization/localization'
import { FormElement, FormElementCheckBox, FormElementDataType, FormElementType } from '../../components/forms/FormElement'
import { GeneralForm, SetCanSubmitFunc, SetFormDatafunc } from '../../components/forms/GeneralForm'
import { FormDataLookup } from '../../components/forms/FormElement'
import { LibraryChart } from '../../types/api'

interface GenerateConfigureGrowersReportProps {
  setFormDataFunc: SetFormDatafunc
  setCanContinueFunc: SetCanSubmitFunc
  formData: FormDataLookup
  libraryCharts: LibraryChart[]
}

export function GenerateConfigureGrowersReport(props: GenerateConfigureGrowersReportProps) {
  const { language } = useContext(PrefsContext)
  const { libraryCharts, formData, setCanContinueFunc } = props

  useEffect(() => {
    const keys = Object.keys(formData)
    for (const key of keys) {
      if (key.startsWith('section.') || key.startsWith('folder.')) {
        setCanContinueFunc(true)
        return
      }
    }
    setCanContinueFunc(false)
  }, [formData, setCanContinueFunc])

  const folders = useMemo((): string[] => {
    const out = [] as string[]
    if (!libraryCharts || libraryCharts.length === 0) {
      return out
    }
    for (const chart of libraryCharts) {
      if (!out.includes(chart.folder)) {
        out.push(chart.folder)
      }
    }
    out.sort()
    return out
  }, [libraryCharts])

  const reportSections = useMemo((): FormElement[] => {
    const out = [
    {
      elementType: FormElementType.Label,
      label: 'Include These Standard Report Sections:',
      labelClass: 'generate-report-element-label'
    } as FormElement,
    {
      elementType: FormElementType.Checkbox,
      dataType: FormElementDataType.Boolean,
      label: 'Summary Page',
      fieldName: 'section.summary',
      defaultChecked: 'section.summary' in formData && formData['section.summary'] === true,
      value: true
    } as FormElementCheckBox,
    {
      elementType: FormElementType.Checkbox,
      dataType: FormElementDataType.Boolean,
      label: GetLocalizedTextItem(language, 'climate', 'upperAllFirst') + ' ' + GetLocalizedTextItem(language, 'charts', 'upperAllFirst'),
      fieldName: 'section.climate',
      defaultChecked: 'section.climate' in formData && formData['section.climate'] === true,
      value: true
    } as FormElementCheckBox,
    {
      elementType: FormElementType.Checkbox,
      dataType: FormElementDataType.Boolean,
      label: GetLocalizedTextItem(language, 'soil', 'upperAllFirst') + ' ' + GetLocalizedTextItem(language, 'charts', 'upperAllFirst'),
      fieldName: 'section.soil',
      defaultChecked: 'section.soil' in formData && formData['section.soil'] === true,
      value: true
    } as FormElementCheckBox,
    {
      elementType: FormElementType.Checkbox,
      dataType: FormElementDataType.Boolean,
      label: GetLocalizedTextItem(language, 'water', 'upperAllFirst') + ' ' + GetLocalizedTextItem(language, 'charts', 'upperAllFirst'),
      fieldName: 'section.water',
      defaultChecked: 'section.water' in formData && formData['section.water'] === true,
      value: true
    } as FormElementCheckBox,
    {
      elementType: FormElementType.Checkbox,
      dataType: FormElementDataType.Boolean,
      label: GetLocalizedTextItem(language, 'airQuality', 'upperAllFirst') + ' ' + GetLocalizedTextItem(language, 'charts', 'upperAllFirst'),
      fieldName: 'section.airQuality',
      defaultChecked: 'section.airQuality' in formData && formData['section.airQuality'] === true,
      value: true
    } as FormElementCheckBox,
    {
      elementType: FormElementType.Checkbox,
      dataType: FormElementDataType.Boolean,
      label: GetLocalizedTextItem(language, 'co2AndGhg', 'upperAllFirst') + ' ' + GetLocalizedTextItem(language, 'charts', 'upperAllFirst'),
      fieldName: 'section.co2AndGhg',
      defaultChecked: 'section.co2AndGhg' in formData && formData['section.co2AndGhg'] === true,
      value: true
    } as FormElementCheckBox,
  ] as FormElementCheckBox[]

  if (!folders || folders.length === 0) {
    return out
  }
  // out.push({
  //   elementType: FormElementType.Label,
  //   label: 'Include These Chart Library Folders:',
  //   labelClass: 'generate-report-element-label mt-3'
  // } as FormElement)
  // for (const folder of folders) {
  //   out.push({
  //     elementType: FormElementType.Checkbox,
  //     dataType: FormElementDataType.Boolean,
  //     label: folder,
  //     fieldName: `folder.${folder}`,
  //     defaultChecked: `folder.${folder}` in formData && formData[`folder.${folder}`] === true,
  //     value: true
  //   } as FormElementCheckBox)
  // }
  return out
  }, [folders, language, formData])

  return <div className="generate-configure-growers-report-form">
    <GeneralForm
        elements={reportSections}
        wrapperClass="short"
        setFormDatafunc={props.setFormDataFunc}
        setCanSubmitFunc={(_: boolean) => {}}
    />
  </div>

}