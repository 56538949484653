import { newUUID } from "../../../utils/uuid"

import {IQueryConfig, DefaultQueryConfigs, IChartDisplayConfig, DefaultChartDisplayConfig } from "../../../types/IQueryConfig"

export function deleteCustomerChartStorage(customer: string) {
  const items = [
    `${customer}.chartBuilder.queryConfigs`,
    `${customer}.chartBuilder.chartDisplayConfig`,
    'charts.timeRange'
  ] as string[]
  for (const item of items) {
    localStorage.removeItem(item)
  }
}

const defaultLinkedUUID = newUUID()

// QUERY CONFIGS - BY CUSTOMER

export function loadBuilderQueryConfigs(customer: string) {
  const qcs = localStorage.getItem(`${customer}.chartBuilder.queryConfigs`)
  if (qcs) {
    return JSON.parse(qcs) as IQueryConfig[]
  }
  const cfg = DefaultQueryConfigs()
  cfg[0].id = defaultLinkedUUID
  return cfg
}

export function saveBuilderQueryConfigs(customer: string, data: string) {
  localStorage.setItem(`${customer}.chartBuilder.queryConfigs`, data)
}

// MAIN CHART DISPlAY CONFIG - BY CUSTOMER

export function loadBuilderChartDisplayConfig(customer: string) {
  const qcs = localStorage.getItem(`${customer}.chartBuilder.chartDisplayConfig`)
  if (qcs) {
    return JSON.parse(qcs) as IChartDisplayConfig
  }
  return DefaultChartDisplayConfig()
}

export function saveBuilderChartDisplayConfig(customer: string, data: string) {
  localStorage.setItem(`${customer}.chartBuilder.chartDisplayConfig`, data)
}

// MAIN CHART CONFIG - GLOBAL

export function loadTimeRange() {
  let tr = localStorage.getItem('charts.timeRange')
  if (!tr) {
    tr = '24h-'
  }
  return tr
}

export function saveTimeRange(data: string) {
  localStorage.setItem('charts.timeRange', data)
}
