import { LabelValuePairs } from '../../types/generic'
import Select from '../Selects/Select'
import './SelectRow.css'

interface SelectRowProps {
  onChange?: any
  labelId?: string
  label?: string
  description?: string
  selectId?: string
  labelClass?: string
  selectClass?: string
  defaultValue?: string | number
  children?: any
  items?: LabelValuePairs
}

export default function SelectRow (props: SelectRowProps) {

  const onChange = (e: any) => {
    if (props && props.onChange) {
      props.onChange(e)
    }
  }
  var labelId = "text-select-row-label"
  var selectId = "text-select-row-select"
  if (props.labelId) {
    labelId = props.labelId
  }
  if (props.selectId) {
    selectId = props.selectId
  }

  const labelClass = "input-group-text " + props.labelClass
  const selectClass = "select-row-select " + props.selectClass

  return (
    <div className="input-group my-2">
      <span className={labelClass} id={labelId}>
        <span className="select-row-label">{props.label}</span>
      </span>
      <Select
        className={selectClass}
        id={selectId}
        aria-label={props.description}
        aria-describedby={labelId}
        onChange={onChange}
        defaultValue={props.defaultValue}
        noWrapper={true}
        items={props.items}
      >
        {(!props.items || props.items.length === 0) && props.children}
      </Select>
    </div>
  )

}
