import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { PrefsContext } from '../../utils/prefs/PrefsContext'
import { GetLocalizedTextItem } from '../../localization/localization'
import { confirmStep, useAuth } from '../../utils/auth/provider'
import { ConfirmSignInOutput, SignInOutput } from "aws-amplify/auth"

import { ButtonConfig } from '../../components/modals/ModalBasic'
import LoginEnterEmailForm from './LoginEnterEmailForm'
import LoginEnterVerificationCodeForm from './LoginEnterVerificationCodeForm'
import PanelBanner from '../../components/notifications/PanelBanner/PanelBanner'

interface LoginPanelProps {
  setButtonsFunc: any
}

export default function LoginPanel(props: LoginPanelProps) {

  const [isLoading, setIsLoading] = useState(false)
  const [currentEmailAddress, setCurrentEmailAddress] = useState('')
  const [otlcCode, setOtlcCode] = useState('')

  const [errorMessage, setErrorMessage] = useState('')

  const prefs = useContext(PrefsContext)
  const { language } = prefs
  const { setButtonsFunc } = props

  const { isAuthenticated, signInFunc, confirmSignInFunc, signOutFunc } = useAuth()

  const [loginStep, setLoginStep] = useState(isAuthenticated ? 2 : 0)

  const np = window.location.hostname !== process.env.REACT_APP_prod_hostname &&
  window.location.hostname !== 'localhost' &&
  window.location.hostname !== '127.0.0.1'

  const sendEmailFunc = useCallback(() => {
    if (!signInFunc) {
      return
    }
    setErrorMessage('')
    console.log('signing in as', currentEmailAddress)
    setIsLoading(true)
    signInFunc(currentEmailAddress.toLowerCase())
      .then((resp: SignInOutput) => {
        if (resp && resp.nextStep && resp.nextStep.signInStep === confirmStep ) {
          console.log('OTLC code was sent', resp)
          setLoginStep(1)
        } else {
          setErrorMessage('Invalid or Unrecognized Email Address')
        }
      })
      .catch((e: any) => {
        console.log('error with auth', e)
        if (e.message) {
          if (('' + e.message).includes('nvalid email address')) {
            setErrorMessage('Invalid or Unrecognized Email Address')
          } else {
            setErrorMessage('An unknown error has occurred.')
          }
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
    }, [currentEmailAddress, setIsLoading, setErrorMessage, signInFunc])

    const handleSubmitCode = useCallback(() => {
      if (!otlcCode || !confirmSignInFunc) {
        return
      }
      setErrorMessage('')
      setIsLoading(true)
      console.log('sending custom challenge code', otlcCode)
      confirmSignInFunc(otlcCode)
        .then((o: ConfirmSignInOutput) => {
          if (o.isSignedIn) {
            console.log('user is logged in')
          } else {
            const message = 'Invalid Sign In Code.'
            console.log(message)
            if (signOutFunc) {
              signOutFunc()
            }
          }
        })
        .catch((e: any) => {
          console.log('error with challenge code auth', e)
          const message = 'Invalid Sign In Code.'
          console.log(message)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }, [setIsLoading, otlcCode, confirmSignInFunc, signOutFunc])

  const buttons = useMemo(() => {
    if (loginStep === 0) {
      return [
        {
          title: GetLocalizedTextItem(language, isLoading ? 'workingEllipses' : 'continue', 'upperAllFirst'),
          className: 'wide',
          color: 'green',
          onClick: sendEmailFunc,
          disabled: !currentEmailAddress || isLoading || (np && !(currentEmailAddress.endsWith('agrology.ag')))
        } as ButtonConfig
      ] as ButtonConfig[]
    }
    if (loginStep === 1) {
      return [
        {
          title: GetLocalizedTextItem(language, isLoading ? 'workingEllipses' : 'continue', 'upperAllFirst'),
          className: 'wide mb-3',
          color: 'green',
          onClick: handleSubmitCode,
          disabled: !otlcCode || isLoading
        },
        {
          title: GetLocalizedTextItem(language, 'startOver', 'upperAllFirst'),
          className: 'wide',
          color: 'green',
          onClick: () => {if(signOutFunc) { signOutFunc()}; setLoginStep(0)},
          disabled: isLoading
        }
      ] as ButtonConfig[]
    }
    return [] as ButtonConfig[]
  }, [loginStep, setLoginStep, language, currentEmailAddress, sendEmailFunc, isLoading, otlcCode, handleSubmitCode, signOutFunc, np])

  useEffect(() => {
    if (setButtonsFunc) {
      setButtonsFunc(buttons)
    }
  }, [setButtonsFunc, buttons])

  return (
    <div>
      {errorMessage &&
        <PanelBanner status="failure" message={errorMessage} showIcon={true} className='centered' />
      }
      <div className="login-content">
        {loginStep === 0 &&
          <LoginEnterEmailForm
            sendEmailFunc={sendEmailFunc}
            setCurrentEmailFunc={setCurrentEmailAddress}
            isLoading={isLoading}
          />
        }
        {loginStep === 1 &&
          <LoginEnterVerificationCodeForm
            setVerificationCodeFunc={setOtlcCode}
            sendVerificationCodeFunc={handleSubmitCode}
            isLoading={isLoading}
            userEmail={currentEmailAddress.toLowerCase() || ''}
          />
        }
      </div>
    </div>
  )
}