import { useState } from "react"
import { parseRelativeTime } from "../../utils/time/relativeTime"

import './AbsoluteTimeSelects.css'
import Select from "../Selects/Select"

interface AbsoluteTimeSelectsProps {
  relativeTime?: string
  absTime?: Date
  relIndex?: number
  updateAbsTime: any
}

export function AbsoluteTimeSelects(props: AbsoluteTimeSelectsProps) {

  let t = props.absTime ? new Date(props.absTime) : new Date(Math.floor(Date.now() / 300000) * 300000)
  if (!(props.absTime) && props.relativeTime) {
    const ets = parseRelativeTime(props.relativeTime)
    const idx = props.relIndex ? props.relIndex : 0
    if (idx < ets.length) {
      t = new Date(Math.floor(ets[idx] / 300) * 300000)
    }
  }

  const [year] = useState(t.getFullYear())
  const [dom] = useState(t.getDate())
  const [month] = useState(t.getMonth())
  const [hour] = useState(t.getHours())
  const [minute] = useState(Math.floor(t.getMinutes() / 5) * 5)

  const updateYear = (i: number) => {
    const t = new Date(i, month, dom, hour, minute, 0, 0)
    if (t !== props.absTime) {
      props.updateAbsTime(t)
    }
  }

  const updateMonth = (i: number) => {
    const t = new Date(year, i, dom, hour, minute, 0, 0)
    if (t !== props.absTime) {
      props.updateAbsTime(t)
    }
  }

  const updateDay = (i: number) => {
    const t = new Date(year, month, i, hour, minute, 0, 0)
    if (t !== props.absTime) {
      props.updateAbsTime(t)
    }
  }

  const updateHour = (i: number) => {
    const t = new Date(year, month, dom, i, minute, 0, 0)
    if (t !== props.absTime) {
      props.updateAbsTime(t)
    }
  }

  const updateMinute = (i: number) => {
    const t = new Date(year, month, dom, hour, i, 0, 0)
    if (t !== props.absTime) {
      props.updateAbsTime(t)
    }
  }

  return (
    <div className="timepicker-select-group">
      <Select className="timepicker-select timepicker-select-year" defaultValue={year.toString()} onChange={(e: any) => { updateYear(parseInt(e.target.value)) }} >
        <option value="2025">2025</option>
        <option value="2024">2024</option>
        <option value="2023">2023</option>
        <option value="2022">2022</option>
        <option value="2021">2021</option>
      </Select>
      <Select className="timepicker-select timepicker-select-month" defaultValue={month.toString()} onChange={(e: any) => { updateMonth(parseInt(e.target.value)) }}>
        <option value={0}>January</option>
        <option value={1}>February</option>
        <option value={2}>March</option>
        <option value={3}>April</option>
        <option value={4}>May</option>
        <option value={5}>June</option>
        <option value={6}>July</option>
        <option value={7}>August</option>
        <option value={8}>September</option>
        <option value={9}>October</option>
        <option value={10}>November</option>
        <option value={11}>December</option>
      </Select>
      <Select className="timepicker-select timepicker-select-day" defaultValue={dom.toString()} onChange={(e: any) => { updateDay(parseInt(e.target.value)) }}  >
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
        <option>5</option>
        <option>6</option>
        <option>7</option>
        <option>8</option>
        <option>9</option>
        <option>10</option>
        <option>11</option>
        <option>12</option>
        <option>13</option>
        <option>14</option>
        <option>15</option>
        <option>16</option>
        <option>17</option>
        <option>18</option>
        <option>19</option>
        <option>20</option>
        <option>21</option>
        <option>22</option>
        <option>23</option>
        <option>24</option>
        <option>25</option>
        <option>26</option>
        <option>27</option>
        <option>28</option>
        <option>29</option>
        <option>30</option>
        <option>31</option>
      </Select>
      <Select className="timepicker-select timepicker-select-hour" defaultValue={hour.toString()} onChange={(e: any) => { updateHour(parseInt(e.target.value)) }}>
        <option value={0}>12a</option>
        <option value={1}>1a</option>
        <option value={2}>2a</option>
        <option value={3}>3a</option>
        <option value={4}>4a</option>
        <option value={5}>5a</option>
        <option value={6}>6a</option>
        <option value={7}>7a</option>
        <option value={8}>8a</option>
        <option value={9}>9a</option>
        <option value={10}>10a</option>
        <option value={11}>11a</option>
        <option value={12}>12p</option>
        <option value={13}>1p</option>
        <option value={14}>2p</option>
        <option value={15}>3p</option>
        <option value={16}>4p</option>
        <option value={17}>5p</option>
        <option value={18}>6p</option>
        <option value={19}>7p</option>
        <option value={20}>8p</option>
        <option value={21}>9p</option>
        <option value={22}>10p</option>
        <option value={23}>11p</option>
      </Select>
      <Select className="timepicker-select timepicker-select-min" defaultValue={minute.toString()} onChange={(e: any) => { updateMinute(parseInt(e.target.value)) }}>
        <option value={0}>00</option>
        <option value={5}>05</option>
        <option value={10}>10</option>
        <option value={15}>15</option>
        <option value={20}>20</option>
        <option value={25}>25</option>
        <option value={30}>30</option>
        <option value={35}>35</option>
        <option value={40}>40</option>
        <option value={45}>45</option>
        <option value={50}>50</option>
        <option value={55}>55</option>
      </Select>
    </div>
  )
}