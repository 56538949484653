
import { useContext, useMemo } from 'react'
import { PrefsContext } from '../../utils/prefs/PrefsContext'

import { growerReportChartScopes, growerReportFilesScopes, reportRanges } from './selectItems'
import { GetLocalizedTextItem } from '../../localization/localization'
import { siteAndNodeOptions as  getSiteAndNodeOptions, SiteAndNodeOptions,
  SiteAndNodeOptionsInput} from '../../components/fleet/SiteAndNodeOptions'
import { FormDataLookup, FormElementTimePicker } from '../../components/forms/FormElement'
import { FormElementDataType, FormElements, FormElementSelect,
  FormElementType } from '../../components/forms/FormElement'
import { GeneralForm, SetCanSubmitFunc, SetFormDatafunc } from '../../components/forms/GeneralForm'

import './GenerateConfigurePDF.css'

interface GenerateConfigurePDFProps {
  setFormDataFunc: SetFormDatafunc
  setCanContinueFunc: SetCanSubmitFunc
  formData: FormDataLookup
}

export function GenerateConfigurePDF(props: GenerateConfigurePDFProps) {

  const { customerAccess, language } = useContext(PrefsContext)
  const { formData } = props
  const { siteId } = formData

  const siteAndNodeOptions = useMemo((): SiteAndNodeOptions => {
    return getSiteAndNodeOptions({
      customerAccess: customerAccess,
      selectedSite: siteId,
      showAllSitesOption: true,
      showAllNodesOption: true,
    } as SiteAndNodeOptionsInput)
  }, [customerAccess, siteId])

  const formElements = useMemo(() => {
    const out = [
      {
        elementType: FormElementType.Select,
        dataType: FormElementDataType.Text,
        label: 'Site',
        fieldName: 'siteId',
        items: siteAndNodeOptions.sites,
        defaultValue: formData.siteId
      } as FormElementSelect,
      {
        elementType: FormElementType.Select,
        dataType: FormElementDataType.Text,
        label: 'Node',
        fieldName: 'nodeId',
        items: siteAndNodeOptions.nodes,
        disabled: !siteAndNodeOptions.selectedSite ||
          siteAndNodeOptions.selectedSite === 'all',
        defaultValue: formData.nodeId
      } as FormElementSelect,
      {
        elementType: FormElementType.Select,
        dataType: FormElementDataType.Text,
        label: 'Time Period',
        fieldName: 'timePeriod',
        items: reportRanges,
        defaultValue: formData.timePeriod
      } as FormElementSelect
    ] as FormElements

    if (formData && 'timePeriod' in formData && formData.timePeriod === 'custom') {
      out.push({
        elementType: FormElementType.TimePicker,
        label: 'Time Period',
        fieldName: 'timeRange',
        timeRange: formData.timeRange
      } as FormElementTimePicker)
    }

    out.push({
      elementType: FormElementType.Select,
      dataType: FormElementDataType.Text,
      label: GetLocalizedTextItem(language, 'chartCount', 'upperAllFirst'),
      fieldName: 'chartCount',
      items: growerReportChartScopes,
      defaultValue: formData.chartCount
      } as FormElementSelect,
    {
      elementType: FormElementType.Select,
      dataType: FormElementDataType.Text,
      label: GetLocalizedTextItem(language, 'fileCount', 'upperAllFirst'),
      fieldName: 'fileCount',
      items: growerReportFilesScopes,
      defaultValue: formData.fileCount
    } as FormElementSelect)

    return out
  }, [siteAndNodeOptions, formData, language])

  return <div className="generate-configure-pdf-form">
    <div className="generate-report-section-header">
      PDF Report Options
    </div>
    <div className="generate-report-section-content">
      <GeneralForm
        elements={formElements}
        labelClass="width-85"
        wrapperClass="mb-2"
        inputClass="wide"
        setFormDatafunc={props.setFormDataFunc}
        setCanSubmitFunc={props.setCanContinueFunc}
      />
    </div>
  </div>
}