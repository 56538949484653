import { createReportDefinition, updateReportDefinition } from '../../utils/api/endpoints'
import { FormDataLookup } from '../../components/forms/FormElement'
import { reportDefFromFormData } from './reportData'

export async function saveReportDefinition(customerId: string, formData: FormDataLookup, defId?: string) {
  const def = reportDefFromFormData(customerId, formData)
  if (defId) {
    return updateReportDefinition(customerId, defId, def)
  }
  return createReportDefinition(customerId, def)
}
