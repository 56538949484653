import { useMemo } from 'react'
import { GeneralForm, SetCanSubmitFunc, SetFormDatafunc } from '../../components/forms/GeneralForm'
import { FormDataLookup, FormElement, FormElementCheckBox, FormElementDataType, FormElementRadioGroup, FormElements, FormElementType } from "../../components/forms/FormElement"
import { LabelValuePairs } from '../../types/generic'
import { supportedCSVMetrics } from './supportedReports'

interface GenerateConfigureCSVReportProps {
  setFormDataFunc: SetFormDatafunc
  setCanContinueFunc: SetCanSubmitFunc
  formData: FormDataLookup
}

export function GenerateConfigureCSVReport(props: GenerateConfigureCSVReportProps) {

  const { formData } = props

  const formElements = useMemo(() => {
    const out = [
      {
        elementType: FormElementType.Label,
        label: 'Time Column Format:',
        labelClass: 'generate-report-element-label'
      } as FormElement,
      {
        elementType: FormElementType.Radio,
        dataType: FormElementDataType.Text,
        fieldName: 'timestampFormat',
        defaultValue: formData.timestampFormat,
        radioWrapperClass: 'width-155 me-2',
        items: [
          {label: 'Human-Readable', value: 'humanReadable'},
          {label: 'Unix Timestamp', value: 'unix'},
        ] as LabelValuePairs
      } as FormElementRadioGroup,
      {
        elementType: FormElementType.Label,
        label: 'Include These Metrics:',
        labelClass: 'generate-report-element-label mt-2'
      } as FormElement
    ] as FormElements

    for (const metric of supportedCSVMetrics) {
      const mv = `csvMetric.${metric.value}`
      out.push({
        elementType: FormElementType.Checkbox,
        dataType: FormElementDataType.Boolean,
        label: metric.label,
        fieldName: mv,
        defaultChecked: mv in formData && formData[mv] === true,
        value: true,
        wrapperClass: 'width-235'
      } as FormElementCheckBox,)

    }
    return out
  }, [formData])


  return <div className="generate-configure-csv-form">
    <div className="generate-report-section-content">
      <GeneralForm
        elements={formElements}
        wrapperClass="short"
        setFormDatafunc={props.setFormDataFunc}
        setCanSubmitFunc={props.setCanContinueFunc}
      />
    </div>
  </div>
}