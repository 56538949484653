import { useMemo } from 'react'

import { CloserFunc } from '../../types/generic'
import { CheckMenuItem } from './CheckMenuItem'
import { ItemClickedFunc, MenuItem } from './MenuItem'

import './ContextMenu.css'

interface ContextMenuProps {
  headerLabel?: string,
  items: MenuItem[],
  posX: number,
  className?: string
  closerFunc: CloserFunc
}

export default function ContextMenu(props: ContextMenuProps) {

  const { posX, className } = props

  const menuItemClicked = (onClick?: ItemClickedFunc) => {
    if (onClick) {
      onClick()
    }
    props.closerFunc()
  }

  const classes = useMemo((): string => {
    const base = 'context-menu-mask'
    if (className) {
      return `${base} ${className}`
    }
    return base
  }, [className])

  const styleOptions = useMemo((): React.CSSProperties | undefined => {
    if (posX !== undefined) {
      return {left: posX}
    }
    return undefined
  }, [posX])

  return (
    <div className={classes} style={styleOptions}>
      <div className="context-menu">
        <ul>
          {props.headerLabel && <li className="context-menu-header add-border-bottom">
            <span className="context-menu-header-label">{props.headerLabel}</span>
          </li>}
        {props.items.map((item, i) => {
          if (item.isSeparator) {
            return <hr className="separator" key={i} />
          }
          return <CheckMenuItem
            onClick={() => {menuItemClicked(item.onClick)}}
            label={item.label || ''}
            isChecked={item.isChecked || false}
            key={i}
          />
        })}
        </ul>
      </div>
    </div>
  )
}