import ModalBasic from "../../components/modals/ModalBasic"
import LSSCredits from "./LSSCredits"
import OSSCredits from "./OSSCredits"
import { VERSION } from "../../app/app"

import './ModalCredits.css'

interface ModalCreditsProps {
  closer?: any
}

export default function ModalCredits(props: ModalCreditsProps) {
  return (
    <ModalBasic
      headerText={process.env.REACT_APP_appShortName + " " + process.env.REACT_APP_appName + " v" + VERSION}
      sz="md"
      closer={props.closer}
    >
      <div className="credits-body">
        <h5>Acknowledgements</h5>
        <div style={{lineHeight: "2em"}}>
          The following Open Source Software is used in {process.env.REACT_APP_appNamePrefix} {process.env.REACT_APP_appName}:
        </div>
        <OSSCredits />
        <div style={{lineHeight: "2em"}}>
          We also license and use the following Commercial Software and/or Services:
        </div>
        <LSSCredits />
      </div>
    </ModalBasic>
  )
}
