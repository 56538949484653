import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { useMemo } from 'react'
import Divider from '../Divider/Divider'

const inProd = window.location.hostname === process.env.REACT_APP_prod_hostname
const inLocalDev = window.location.hostname === 'localhost' ||
  window.location.hostname === '127.0.0.1'

export interface MainNavLinkProps {
  id: string
  label: string
  to: string
  icon?: IconDefinition
  iconClassName?: string
  className?: string
  cellClassName?: string
  isDivider?: boolean
  onClick?: any
  hideInProd?: boolean
  showInLocalDevOnly?: boolean
}

export default function MainNavLink(props: MainNavLinkProps) {
  const { id, iconClassName } = props
  const iconId = useMemo(() => {
    return `${id}Icon`
  }, [id])

  const iconClass = useMemo(() => {
    const base = 'leftnav-icon'
    if (iconClassName) {
      return `${base} ${iconClassName}`
    }
    return base
  }, [iconClassName])

  if ((props.showInLocalDevOnly && !inLocalDev) || (props.hideInProd && inProd)) {
    return <></>
  }

  if (props.onClick) {
    return <div>
      <li onClick={props.onClick}>
        {props.icon && <FontAwesomeIcon id={iconId} icon={props.icon} className={iconClass} /> }
        {props.label}
      </li>
    </div>
  }

  if (props.isDivider) {
    return <Divider />
  }

  return (
  <NavLink className={({ isActive, isPending }) => isActive || isPending ? 'selected' : ''} to={props.to} id={id}>
    <li className={props.cellClassName}>
      {props.icon && <FontAwesomeIcon id={iconId} icon={props.icon} className={iconClass} /> }
      {props.label}
    </li>
  </NavLink>
  )
}