import { Route, Routes as DomRoutes } from 'react-router-dom'

import { Login } from '../views/auth/Login'
import { NoAccess } from '../views/errors/NoAccess'
import ErrorPage from '../views/errors/ErrorPage'

import SecureRoutes, { secureRouteChildren } from './SecureRoutes'

export default function Routes() {
  return (
    <DomRoutes>
      <Route path="/login" element={<Login />} />
      <Route path="/no-access" element={<NoAccess />} />
      <Route path="/"
        element={<SecureRoutes />}
        errorElement={<ErrorPage />}
        children={secureRouteChildren}
      />
    </DomRoutes>
  )
}
