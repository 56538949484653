import Select from "../../../components/Selects/Select"

import './SourceSelector.css'

interface SourceSelectorProps {
  onChange: any
  disabled?: boolean
  source?: string
  labelClass?: string
  className?: string
}

export default function SourceSelector(props: SourceSelectorProps) {
  return (
    <div className="source-selector">
      <Select
        label="Source"
        onChange={props.onChange}
        disabled={props.disabled}
        defaultValue={props.source}
        labelClass={props.labelClass}
        className={`wide mb-2 ${props.className}`}
      >
        <option value="">-- Select Source --</option>
        <option value="gt-historical">Ground-truth</option>
        <option value="weather-historical">Historical Weather</option>
        <option value="synthetic">Historical Synthetics</option>
        <option value="nanoclimate-forecast">Nanoclimate Forecast</option>
        <option value="weather-forecast">Weather Forecast</option>
      </Select>
    </div>
  )
}
