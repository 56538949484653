import { useCallback, useMemo, useState } from 'react'
import { deleteLibraryChart } from '../../../utils/api/endpoints'
import { ApiStep } from '../../../types/api'
import { GeneralStatus } from '../../../types/generic'

import ModalBasic, { ButtonConfig, StatusBanner } from '../../../components/modals/ModalBasic'
import { faChartArea } from '@fortawesome/free-solid-svg-icons'

import './ModalDeleteLibraryChart.css'

interface ModalDeleteLibraryChartProps {
  closer?: any
  customerID?: string | undefined
  customerDN: string | undefined
  chartID?: string
}

export function ModalDeleteLibraryChart(props: ModalDeleteLibraryChartProps) {

  const [closeRequested, setCloseRequested] = useState(false)
  const [step, setStep] = useState(ApiStep.Confirm)
  const { customerID, chartID, customerDN } = props

  const deleteChart = useCallback(() => {
    if (!customerID || !chartID) {
      console.log('no customer selected, not saving shart')
      return
    }
    setStep(ApiStep.InProgress)
    deleteLibraryChart(customerID, chartID)
    .then(() => {
      setStep(ApiStep.Done)
      setTimeout(() => {setCloseRequested(true)}, 1500)
    })
    .catch((e) => {
      setStep(ApiStep.Error)
      console.log('chart save failed', e)
    })
  }, [customerID, setStep, setCloseRequested, chartID])

  const buttonConfigs = useMemo(() => {
    return (
      [{
        title: 'Yes, Delete This Chart',
        disabled: step !== ApiStep.Confirm,
        onClick: deleteChart,
        className: 'me-3',
        color: 'red'
      }] as ButtonConfig[]
    )
  }, [step, deleteChart])

  return (
    <ModalBasic
      closer={props.closer}
      closeLabel="Cancel"
      headerText="Delete Chart from Library"
      headerIcon={faChartArea}
      sz="sm"
      buttonConfigs={buttonConfigs}
      closeRequested={closeRequested}
      closeable={step !== ApiStep.InProgress}
    >
      <div className="modal-delete-chart">
        <div className="modal-delete-chart-body">

          {step === ApiStep.Confirm && <>
          <StatusBanner
            label="Confirm Chart Deletion"
            status={GeneralStatus.Caution}
          />
            Are you sure you want to delete this chart?
            Deletions are permanent and can't be undone.
            <br /><br />
            This will affect all users at {customerDN}.
          </>}

          {step === ApiStep.InProgress &&
          <StatusBanner
            isLarge={true}
            label="Working..."
            className="chart-delete-in-progress"
            status={GeneralStatus.Muted}
            />}

          {step === ApiStep.Done &&
          <StatusBanner
            isLarge={true}
            label="Chart Successfully Deleted"
            status={GeneralStatus.OK}
          />}

          {step === ApiStep.Error && <>
          <StatusBanner
            isLarge={true}
            label="Chart Deletion Failed"
            status={GeneralStatus.Failure}
          />
          <div className="mb-2">Your Chart was not deleted due to a system error. We're really sorry about this! Please contact Agrology for further assistance.</div>
          </>}

        </div>
      </div>
    </ModalBasic>
  )
}