import { Navigate, Outlet, Route } from 'react-router-dom'
import { useAuth } from '../utils/auth/provider'

import AppHeader from '../layout/AppHeader'
import AppFooter from '../layout/AppFooter'
import { LeftNav } from "../layout/LeftNav"

import { Overview } from '../views/overview/Overview'
import { Charts } from '../views/charts/Charts'
import { Alerting } from '../views/alerting/Alerting'
import { Maintenance } from '../views/maintenance/Maintenance'
import { Sites } from '../views/sites/Sites'
import { Access } from '../views/access/Access'
import { ChangeCustomer } from '../views/changecustomer/ChangeCustomer'
import { Reports } from '../views/reports/Reports'
import { Testing } from '../views/testing/Testing'

// SecureRoute means login is required to access these resources
export default function SecureRoutes() {

  const { isAuthenticated, isInitializing } = useAuth()
  if (isInitializing) {
    return <div>Loading ...</div>
  }
  if (!isAuthenticated) {
    return <Navigate to='/login'/>
  }
  return <>
    <AppHeader />
    <div className="container secure-app">
      <LeftNav />
      <Outlet />
    </div>
    <AppFooter />
  </>
}

export const secureRouteChildren = <>
  <Route index={true} element={<Overview />} />

  <Route path="/customer/:customerId" element={<ChangeCustomer />} />

  <Route path="/charts" element={<Charts />} />
  <Route path="/charts/:section" element={<Charts />} />
  <Route path="/charts/:section/:customerId/:elementId" element={<Charts />} />

  <Route path="/reports" element={<Reports />} />
  <Route path="/reports/:section" element={<Reports />} />
  <Route path="/reports/:section/:customerId/:elementId" element={<Reports />} />

  <Route path="/alerting" element={<Alerting />} />
  <Route path="/maintenance" element={<Maintenance />} />
  <Route path="/sites" element={<Sites />} />
  <Route path="/access" element={<Access />} />

  <Route path="/testing" element={<Testing />} />
</>
