import { useEffect, useMemo, useState, useContext, useCallback } from 'react'
import { useAuth } from '../../utils/auth/provider'
import { PrefsContext } from '../../utils/prefs/PrefsContext'
import { CloserFunc, GeneralStatus } from '../../types/generic'
import { FormDataLookup, removeDeletes } from '../../components/forms/FormElement'
import { faRectangleList } from '@fortawesome/free-solid-svg-icons'
import { LibraryChart, ReportDefinition, ReportRun } from '../../types/api'
import { createReportRun, getLibraryChartsByCustomer } from '../../utils/api/endpoints'
import { CSV, DefaultFormData, formDataFromReportDef, PDF, ReportStep } from './reportData'

import ModalBasic, { ButtonConfig, StatusBanner } from '../../components/modals/ModalBasic'
import { GenerateSelectFormat } from './GenerateSelectFormat'
import { GenerateConfigurePDF } from './GenerateConfigurePDF'
import { GenerateConfigureCSV } from './GenerateConfigureCSV'
import { GenerateConfigureGrowersReport } from './GenerateConfigureGrowersReport'
import { GenerateConfigureDelivery } from './GenerateConfigureDelivery'
import { GenerateConfigureSchedule } from './GenerateConfigureSchedule'
import { GenerateConfigureCSVReport } from './GenerateConfigureCSVReport'

import { saveReportDefinition } from './ModalEditGenerateReport.api'
import './ModalEditGenerateReport.css'

interface ModalEditGenerateReportProps {
  closer: CloserFunc
  reportDefinition?: ReportDefinition
}

const UniformButtonWidth = 'width-95'
const BACK = 'Back'
const CONTINUE = 'Continue'

export function ModalEditGenerateReport(props: ModalEditGenerateReportProps) {

  const { reportDefinition } = props
  const [formData, setFormData] = useState(reportDefinition ? formDataFromReportDef(reportDefinition) : DefaultFormData)
  const [reportFormat, setReportFormat] = useState(props.reportDefinition ? props.reportDefinition.reportFormat || 'pdf' : undefined as string | undefined)
  const [step, setStep] = useState(props.reportDefinition ? ReportStep.SetFormatParameters : ReportStep.SelectFormat)
  const [canContinue, setCanContinue] = useState(false)
  const [canNavigate, setCanNavigate] = useState(true)
  const [libraryCharts, setLibraryCharts] = useState([] as LibraryChart[])
  const [isClosing, setIsClosing] = useState(false)
  const { customer, isCustomerEditor } = useContext(PrefsContext)
  const { username } = useAuth()

  const saveRestricted = useMemo((): boolean => {
    if (isCustomerEditor || !reportDefinition) {
      return false
    }
    return reportDefinition.createdBy !== username
  }, [isCustomerEditor, reportDefinition, username])

  const defOwner = useMemo((): string => {
    if (!saveRestricted) {
      return ''
    }
    if (!reportDefinition || !reportDefinition.createdBy) {
      return 'someone else'
    }
    return reportDefinition.createdBy
  }, [reportDefinition, saveRestricted])

  const isUpdate = useMemo((): boolean => {
    return reportDefinition ? true : false
  }, [reportDefinition])

  useEffect(() => {
    getLibraryChartsByCustomer(customer)
    .then((resp) => {
      if (resp && resp.length > 0) {
        setLibraryCharts(resp)
      }
    })
  }, [setLibraryCharts, customer])

  const onSubmit = useCallback(() => {
    const defId = reportDefinition ? reportDefinition.id : undefined
    saveReportDefinition(customer, formData, defId)
    .then((resp: any) => {
      setStep(ReportStep.SettingsSaved)
      if (formData.generateNow) {
        if (defId) {
          createReportRun(customer, {reportDefinitionID: defId} as ReportRun)
          .then(() => {
            setTimeout(() => {setIsClosing(true)}, 1500)
          })
        } else if (resp && resp.data && resp.data.id) {
          createReportRun(customer, {reportDefinitionID: resp.data.id} as ReportRun)
          .then(() => {
            setTimeout(() => {setIsClosing(true)}, 1500)
          })
        }
      } else {
        setTimeout(() => {setIsClosing(true)}, 1500)
      }
    })
  }, [formData, customer, reportDefinition])

  const formDataChanged = useCallback((fd: FormDataLookup) => {
    const out = Object.assign({ ...formData } as FormDataLookup, fd)
    removeDeletes(out)
    setFormData(out)
  }, [formData])

  const formatSelected = (format: string) => {
    if (format) {
      setReportFormat(format)
      formDataChanged({reportFormat: format} as FormDataLookup)
      setStep(ReportStep.SetFormatParameters)
    }
  }

  const headerText = useMemo((): string => {
    if (reportDefinition) {
      return "Update Report Settings"
    }
    if (step === ReportStep.SelectFormat) {
      return 'Generate a Report'
    }
    if (reportFormat === CSV) {
      return 'Generate a CSV Report'
    }
    if (reportFormat === PDF) {
      return 'Generate a PDF Report'
    }
    return 'Generate a Report'
  }, [reportFormat, reportDefinition, step])

  const submitButtonLabel = useMemo((): string => {
    if (formData.generateNow && formData.generateRecurring) {
      if (isUpdate) {
        return 'Update Settings + Generate'
      }
      return 'Generate + Schedule Report'
    }
    if (formData.generateRecurring) {
      if (isUpdate) {
        return 'Update Report Settings'
      }
      return 'Schedule Report'
    }
    if (formData.generateNow) {
      if (isUpdate) {
        return 'Update Settings + Generate'
      }
      return 'Generate Report'
    }
    if (isUpdate) {
      return 'Update Report Settings'
    }
    return 'Create Report'

  }, [formData, isUpdate])

  const buttons = useMemo((): ButtonConfig[] => {
    if (step === ReportStep.SetFormatParameters) {
      return [
        {
          title: BACK,
          onClick: () => {setStep(ReportStep.SelectFormat)},
          className: UniformButtonWidth
        },
        {
          title: CONTINUE,
          onClick: () => {setStep(ReportStep.SetReportTypeParameters)},
          disabled: !canContinue,
          className: UniformButtonWidth
        }
      ] as ButtonConfig[]
    }
    if (step === ReportStep.SetReportTypeParameters) {
      return [
        {
          title: BACK,
          onClick: () => {setStep(ReportStep.SetFormatParameters)},
          className: UniformButtonWidth
        },
        {
          title: CONTINUE,
          onClick: () => {setStep(ReportStep.SetDeliveryParameters)},
          disabled: !canContinue,
          className: UniformButtonWidth
        }
      ] as ButtonConfig[]
    }
    if (step === ReportStep.SetDeliveryParameters) {
      return [
        {
          title: BACK,
          onClick: () => {setStep(ReportStep.SetReportTypeParameters)},
          className: UniformButtonWidth,
          disabled: !canNavigate
        },
        {
          title: CONTINUE,
          onClick: () => {setStep(ReportStep.SetScheduleParameters)},
          disabled: !canContinue || !canNavigate,
          className: UniformButtonWidth
        }
      ] as ButtonConfig[]
    }
    if (step === ReportStep.SetScheduleParameters) {
      return [
        {
          title: BACK,
          onClick: () => {setStep(ReportStep.SetDeliveryParameters)},
          className: UniformButtonWidth
        },
        {
          title: submitButtonLabel,
          onClick: onSubmit,
          disabled: !canContinue || saveRestricted
        }
      ] as ButtonConfig[]
    }
    return [] as ButtonConfig[]
  }, [step, canContinue, submitButtonLabel, onSubmit, canNavigate, saveRestricted])

  return <ModalBasic
    sz="sm"
    closer={props.closer}
    closeLabel="Cancel"
    headerText={headerText}
    headerIcon={faRectangleList}
    buttonConfigs={buttons}
    useXCloser={true}
    spreadButtons={true}
    closeRequested={isClosing}
  >
    <div className="modal-generate-report-body">
      {step === ReportStep.SelectFormat &&
        <GenerateSelectFormat formatSelectedFunc={formatSelected}/>
      }
      {step === ReportStep.SetFormatParameters && reportFormat === PDF &&
        <GenerateConfigurePDF
          setFormDataFunc={formDataChanged}
          setCanContinueFunc={setCanContinue}
          formData={formData}
        />
      }
      {step === ReportStep.SetReportTypeParameters && reportFormat === PDF &&
        <GenerateConfigureGrowersReport
          setFormDataFunc={formDataChanged}
          setCanContinueFunc={setCanContinue}
          formData={formData}
          libraryCharts={libraryCharts}
        />
      }
      {step === ReportStep.SetFormatParameters && reportFormat === CSV &&
        <GenerateConfigureCSV
          setFormDataFunc={formDataChanged}
          setCanContinueFunc={setCanContinue}
          formData={formData}
        />
      }
      {step === ReportStep.SetReportTypeParameters && reportFormat === CSV &&
        <GenerateConfigureCSVReport
          setFormDataFunc={formDataChanged}
          setCanContinueFunc={setCanContinue}
          formData={formData}
        />
      }
      {step === ReportStep.SetDeliveryParameters &&
        <GenerateConfigureDelivery
          setFormDataFunc={formDataChanged}
          setCanContinueFunc={setCanContinue}
          setCanNavigateFunc={setCanNavigate}
          formData={formData}
        />
      }
      {step === ReportStep.SetScheduleParameters &&
        <GenerateConfigureSchedule
          setFormDataFunc={formDataChanged}
          setCanContinueFunc={setCanContinue}
          formData={formData}
        />
      }
      {step === ReportStep.SettingsSaved && reportDefinition &&
      <StatusBanner
        isLarge={true}
        label="Report Settings Updated"
        className="report-save-success"
        status={GeneralStatus.OK}
      />
      }
      {step === ReportStep.SettingsSaved && !reportDefinition &&
      <StatusBanner
        isLarge={true}
        label="Report Created"
        className="report-save-success"
        status={GeneralStatus.OK}
      />
      }
      {saveRestricted &&
      <div className="notice">
        <hr />
        <div className="caution">
            You can't modify this Report's settings because it is owned by {defOwner}.
        </div>
      </div>
      }
    </div>
  </ModalBasic>
}