import { IQueryConfig, DefaultQueryConfig, IChartDisplayConfig } from "../../types/IQueryConfig"

const decodeSource = (input: string): string => {
  if (input === 'wh') {
    return 'weather-historical'
  }
  if (input === 's') {
    return 'synthetic'
  }
  if (input === 'nf') {
    return 'nanoclimate-forecast'
  }
  if (input === 'wf') {
    return 'weather-forecast'
  }
  return 'gt-historical'
}

const encodeSource = (input: string): string => {
  if (input === 'weather-historical') {
    return 'wh'
  }
  if (input === 'synthetic') {
    return 's'
  }
  if (input === 'nanoclimate-forecast') {
    return 'nf'
  }
  if (input === 'weather-forecast') {
    return 'wf'
  }
  return 'gth'
}

export const getHash = (cust: string, timeRange: string, qcs?: IQueryConfig[],
  cdc?: IChartDisplayConfig, title?: string): string => {
    if (!cdc && (!qcs || qcs.length === 0)) {
      return ''
    }
    let hash = `c=${cust};tr=${timeRange}`
    if (cdc) {
      if (cdc.y1Max !== undefined) {
        hash += `;y1x=${cdc.y1Max}`
      }
      if (cdc.y1Min !== undefined) {
        hash += `;y1n=${cdc.y1Min}`
      }
      if (cdc.y2Max !== undefined) {
        hash += `;y2x=${cdc.y2Max}`
      }
      if (cdc.y2Min !== undefined) {
        hash += `;y2n=${cdc.y2Min}`
      }
      if (title) {
        hash += `;t=${title.replaceAll(' ', '+')}`
      } else if (cdc.title) {
        hash += `;t=${cdc.title.replaceAll(' ', '+')}`
      }
    }
    if (!qcs || qcs.length === 0) {
      return hash
    }
    for (const qc of qcs) {
      let part = 'q='
      let sep = ''
      if (qc.site) {
        part += `${sep}st:${qc.site}`
        sep = ','
      }
      if (qc.node) {
        part += `${sep}n:${qc.node}`
        sep = ','
      }
      if (qc.source) {
        part += `${sep}sc:${encodeSource(qc.source)}`
        sep = ','
      }
      if (qc.metric) {
        part += `${sep}m:${qc.metric}`
        sep = ','
      }
      if (qc.axis && qc.axis === 'y2') {
        part += `${sep}a:2`
        sep = ','
      }
      if (qc.style && qc.style === 'bar') {
        part += `${sep}ls:2`
        sep = ','
      }
      if (qc.useBuckets) {
        part += `${sep}bk:1`
        sep = ','
      }
      if (qc.bucketSize) {
        part += `${sep}bs:${qc.bucketSize}`
        sep = ','
      }
      if (qc.bucketOp) {
        part += `${sep}bo:${qc.bucketOp}`
        sep = ','
      }
      hash += ';' + part
    }
    return hash
  }

export const updateHash = (cust: string, timeRange: string, qcs?: IQueryConfig[],
  cdc?: IChartDisplayConfig,
  ) => {
    if (!cust || !timeRange) {
      return
    }
    window.location.hash = getHash(cust, timeRange, qcs, cdc)
}

const parseQueryConfig = (qs: string): IQueryConfig | undefined => {
  if (!qs || qs.length < 10) {
    return undefined
  }
  const parts = qs.split(',')
  const q = DefaultQueryConfig()
  for (const part of parts) {
    if (part.startsWith('st:')) {
      q.site = part.replace('st:', '')
      continue
    }
    if (part.startsWith('n:')) {
      q.node = part.replace('n:', '')
      continue
    }
    if (part.startsWith('sc:')) {
      q.source = decodeSource(part.replace('sc:', ''))
      continue
    }
    if (part.startsWith('m:')) {
      q.metric = part.replace('m:', '')
      continue
    }
    if (part.startsWith('a:')) {
      q.axis = `y${part.replace('a:', '')}`
      continue
    }
    if (part.startsWith('ls:')) {
      q.style = part === 'ls:2' ? 'bar' : 'line'
      continue
    }
    if (part.startsWith('bk:')) {
      q.useBuckets = part === 'bk:1'
      continue
    }
    if (part.startsWith('bs:')) {
      try {
        const i = parseInt(part.replace('bs:', ''))
        q.bucketSize = i
      }
      catch {
        console.log('failed to parse bucketSize:', part)
      }
      continue
    }
    if (part.startsWith('bo:')) {
      q.bucketOp = part.replace('bo:', '')
      continue
    }
  }
  if (q.useBuckets) {
    if (!q.bucketOp) {
      q.bucketOp = 'avg'
    }
    if (!q.bucketSize) {
      q.bucketSize = 1800
    }
  }
  return q
}

export const getTimeRangeFromHash = (): string | undefined => {
  if (window.location.hash && window.location.hash.length > 5) {
    const parts = window.location.hash.substring(1).split(';')
    for (const part of parts) {
      if (part.startsWith('tr=')) {
        return part.replace('tr=', '')
      }
    }
  }
  return undefined
}

export const getDisplayConfigFromHash = (): IChartDisplayConfig | undefined => {
  const out = {} as IChartDisplayConfig
  var found = false
  if (window.location.hash && window.location.hash.length > 10) {
    const parts = window.location.hash.substring(1).split(';')

    for (const part of parts) {
      if (part.startsWith('y1x=')) {
        try {
          out.y1Max = parseInt(part.replace('y1x=', ''))
          found = true
        }
        catch {
          console.log('failed to parse y1max')
        }
        continue
      }
      if (part.startsWith('y1n=')) {
        try {
          out.y1Min = parseInt(part.replace('y1n=', ''))
          found = true
        }
        catch {
          console.log('failed to parse y1min')
        }
        continue
      }
      if (part.startsWith('y2x=')) {
        try {
          out.y2Max = parseInt(part.replace('y2x=', ''))
          found = true
        }
        catch {
          console.log('failed to parse y2max')
        }
        continue
      }
      if (part.startsWith('y2n=')) {
        try {
          out.y2Min = parseInt(part.replace('y2n=', ''))
          found = true
        }
        catch {
          console.log('failed to parse y2min')
        }
        continue
      }
      if (part.startsWith('t=')) {
        out.title = part.replace('t=', '').replaceAll('+', ' ')
        found = true
      }
    }
  }
  if (found) {
    return out
  }
  return undefined
}

export const getCustomerFromHash = (): string | undefined => {
  if (window.location.hash && window.location.hash.length > 10) {
    const parts = window.location.hash.substring(1).split(';')
    for (const part of parts) {
      if (part.startsWith('c=')) {
        return part.replace('c=', '')
      }
    }
  }
  return undefined
}

export const getQueryConfigsFromHash = (): IQueryConfig[] => {
  const queries = [] as IQueryConfig[]
  if (window.location.hash && window.location.hash.length > 10) {
    const parts = window.location.hash.substring(1).split(';')
    for (const part of parts) {
      if (part.startsWith('q=')) {
        const q = parseQueryConfig(part.replace('q=', ''))
        if (q) {
          queries.push(q)
        }
      }
    }
  }
  return queries
}
