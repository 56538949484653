import { createContext, useEffect, useContext, useState, useCallback } from 'react'
import { Hub } from 'aws-amplify/utils'
import {
  fetchAuthSession, getCurrentUser, AuthSession,
  signIn as awsSignIn, signOut as awsSignOut, SignInInput, SignInOutput,
  confirmSignIn as awsConfirmSignIn, ConfirmSignInInput, ConfirmSignInOutput,
  AuthUser,
} from 'aws-amplify/auth'

export interface IAuthContextType {
  isInitializing: boolean
  isAuthenticated: boolean
  codeEmailedTo?: string

  signInFunc?: any
  confirmSignInFunc?: any
  signOutFunc?: any

  username?: string
}

// Create a context object
export const AuthContext = createContext<IAuthContextType>({
  isInitializing: true,
  isAuthenticated: false
})

interface IAuthProviderProps {
  children: React.ReactNode
}

export const confirmStep = 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE'

// Create a provider for components/ to consume and subscribe to changes
const AuthProvider = ({ children }: IAuthProviderProps) => {
  const [isInitializing, setIsInitializing] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [codeEmailedTo, setCodeEmailedTo] = useState(undefined as string | undefined)
  const [username, setUsername] = useState(undefined as string | undefined)

  const signIn = async (email: string) => {
    email = email.toLowerCase()
    try {
      const response = await awsSignIn({
        username: email,
        options: {
          authFlowType: 'CUSTOM_WITHOUT_SRP'
        }
      } as SignInInput)
      if (response.nextStep.signInStep === confirmStep) {
        setCodeEmailedTo(email)
      }
      return response
    } catch (error) {
      console.log('error signing in', email, error)
      return {isSignedIn: false} as SignInOutput
    }
  }

  const confirmSignIn = async (code: string) => {
    try {
      const response = await awsConfirmSignIn({challengeResponse: code } as ConfirmSignInInput)
      return response
    } catch (error) {
      console.log('error confirming sign-in code', error)
      return {isSignedIn: false} as ConfirmSignInOutput
    }
  }

  const signOut = async () => {
    try {
      await awsSignOut()
    }
    catch (error) {
      console.log('error signing out', error)
    }
    finally {
      return Promise<void>
    }
  }


  const updateAuthSession = useCallback(() => {
    const setNotSignedIn = () => {
      setUsername(undefined)
      setIsAuthenticated(false)
    }
    fetchAuthSession()
    .then((sess: AuthSession) =>{
      if (!sess || !sess.tokens || !sess.tokens.accessToken || !sess.tokens.idToken) {
        setNotSignedIn()
      } else {
        getCurrentUser()
        .then((a: AuthUser) => {
          const email = a && a.signInDetails && a.signInDetails.loginId ? a.signInDetails.loginId : undefined
          if (email) {
            setUsername(email)
            setIsAuthenticated(true)
            setCodeEmailedTo(undefined)
          }
        })
      }
    })
    .catch((err: any) => {
      setNotSignedIn()
      console.log('Error Fetching Auth Session:', err)
    })
    .finally(() => {
      setIsInitializing(false)
    })
  }, [])

  useEffect(() => {
    updateAuthSession()
    // this watches for AWS events like when the user has logged in or out, etc.
    const authListener = Hub.listen('auth',
      async ({ payload: { event, message } }) => {
        console.log('Auth Status Changed:', event, '/', message)
        // this forces a context refresh when AWS updates the user login status
        updateAuthSession()
      }
    )
    // cleanup
    return () => {
      authListener()
    }
  }, [updateAuthSession])

  return <AuthContext.Provider value={
    {
      isAuthenticated: isAuthenticated,
      isInitializing: isInitializing,
      isConfirmingCode: codeEmailedTo,
      username: username,
      signInFunc: signIn,
      confirmSignInFunc: confirmSignIn,
      signOutFunc: signOut
    } as IAuthContextType}>{children}</AuthContext.Provider>
}

export const useAuth = () => useContext(AuthContext) as IAuthContextType

export const accessToken = async () => {
  try {
    const sess = await fetchAuthSession()
    if (sess.tokens && sess.tokens.idToken) {
      return sess.tokens.idToken.toString()
    }
    return null
  }
  catch (e: any) {
    console.log('failed to get session', e)
    return Promise.reject(e)
  }
}

export default AuthProvider
