import { useMemo } from "react"

import { Metric } from "../../../types/api"
import SelectFlyout from "../../../components/Selects/SelectFlyout"
import Select from "../../../components/Selects/Select"
import { MenuTreeItem } from "../../../components/forms/SelectEx"

import './MetricSelector.css'

const allCategories = ["Climate", "CO2", "Air", "Soil", "Water", "GHG"]

interface MetricSelectorProps {
  onChange?: any
  disabled?: boolean
  defaultValue?: any
  source?: string
  metrics: Metric[]
  labelClass?: string
}

interface MetricsOptionsProps {
  metrics: Metric[]
}

interface MenuTreeItemLookup {
  [key: string]: MenuTreeItem
}

function MetricsOptions(props: MetricsOptionsProps): any {
  return (
    props.metrics.map((metric: Metric) => <option key={metric.id} value={metric.id}>{metric.displayName}</option>)
  )
}

export function MetricSelector(props: MetricSelectorProps) {

  const { metrics } = props

  const menu = useMemo((): MenuTreeItem[] => {
    const out = [] as MenuTreeItem[]
    const topLevel = {} as MenuTreeItemLookup
    const keys = [] as string[]
    for (const metric of metrics) {
      if (!metric.category) {
        continue
      }
      if (!(metric.category in topLevel)) {
        keys.push(metric.category)
        topLevel[metric.category] = {
          label: metric.category,
          childMenu: [{
            value: metric.id,
            label: metric.displayName
          }]
        }
      } else {
        topLevel[metric.category].childMenu?.push(
          {
            value: metric.id,
            label: metric.displayName
          }
        )
      }
    }
    for (const key in topLevel) {
      topLevel[key].childMenu?.sort((a, b) => {
        if (!a.label && !b.label) {
          return 0
        }
        if (!a.label) {
          return -1
        }
        if (!b.label) {
          return 1
        }
        if (a.label < b.label)
          return -1
        if (a.label > b.label)
          return 1
        return 0
      })
    }
    for (const key of allCategories) {
      if (keys.includes(key)) {
        out.push(topLevel[key])
      }
    }
    return out
  }, [metrics])

  if (props.source === 'gt-historical') {
    return (
      <div className="metric-selector">
        <SelectFlyout
          className="query-pane-select wide mb-2"
          disableTopLevelChecks={true}
          menuClassName="metric-category"
          menu={menu}
          label="Metric"
          labelClass={props.labelClass}
          onChange={props.onChange}
          disabled={props.disabled}
          defaultValue={props.defaultValue}
          placeholder="-- Select Metric --"
        />
      </div>
    )
  }

  return (
    <div className="metric-selector">
      <Select
        className="query-pane-select wide mb-2"
        label="Metric"
        labelClass={props.labelClass}
        onChange={props.onChange}
        disabled={props.disabled}
        defaultValue={props.defaultValue}
        key={'metrics-list-' + props.metrics.length}
      >
        <option value="">-- Select Metric --</option>
        <MetricsOptions metrics={props.metrics} />
      </Select>
    </div>
  )

}
