import { useMemo } from 'react'
import { LibraryChart } from '../../../types/api'
import SelectRow from '../../../components/forms/SelectRow'
import { LabelValuePairs } from '../../../types/generic'
import { defaultFolderOptions } from './folders'

interface FolderSelectorProps {
  onChange?: any
  labelId?: string
  label?: string
  description?: string
  selectId?: string
  labelClass?: string
  selectClass?: string
  defaultValue?: string | number
  charts?: LibraryChart[]
  showAddNew?: boolean
}

export default function FolderSelector (props: FolderSelectorProps) {
  const { charts, showAddNew } = props
  let labelId = "text-select-row-label"
  let selectId = "text-select-row-select"
  if (props.labelId) {
    labelId = props.labelId
  }
  if (props.selectId) {
    selectId = props.selectId
  }

  const folders = useMemo((): LabelValuePairs => {
    const out = [] as LabelValuePairs
    const fl = defaultFolderOptions()
    if (charts && charts.length > 0) {
      for (const chart of charts) {
        if (!chart.folder || fl.includes(chart.folder)) {
          continue
        }
        fl.push(chart.folder)
      }
    }
    fl.sort()
    for (const folder of fl) {
      out.push({label: folder, value: folder})
    }
    if (showAddNew) {
      out.push({label: '──────────────', value: ''})
      out.push({label: '+ New Folder  ...', value: 'new'})
    }
    return out
  }, [charts, showAddNew])

  return (
    <SelectRow
      labelClass={props.labelClass}
      selectClass={props.selectClass}
      selectId={selectId}
      onChange={props.onChange}
      defaultValue={props.defaultValue}
      label={props.label}
      labelId={labelId}
      description={props.description}
      items={folders}
    />
  )

}
