import TextInput from '../../../components/TextInput/TextInput'

import './ChartTitleInput.css'

interface ChartTitleInputProps {
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  defaultValue?: string
}

export default function ChartTitleInput(props: ChartTitleInputProps) {
  return (
    <TextInput
      className="chart-title-input"
      placeholder="Enter a Chart Title"
      onChange={props.onChange}
      defaultValue={props.defaultValue}
    />
  )
}
