import {IQueryConfig, DefaultQueryConfig, IChartDisplayConfig } from '../../../types/IQueryConfig'

import ChartTitleInput from './ChartTitleInput'
import QueryPane from './QueryPane'
import YAxisConfig from './YAxisConfig'
import TimePicker from '../../../components/TimePicker/TimePicker'

import BasicPanel from '../../../components/Panels/basic/BasicPanel'
import ButtonBasic from '../../../components/Buttons/ButtonBasic'

import './BuilderPanel.css'

interface AddQueryLinkProps {
  onClick: any
}

export function AddQueryLink(props: AddQueryLinkProps) {
  return (
    <>
      <div className="add-query-section">
        <ButtonBasic
          onClick={props.onClick}
          size="sm"
          label="+ Add Query"
          outline={true}
        />
      </div>
    </>
  )
}

interface BuilderPanelProps {
  queryConfigs: IQueryConfig[]
  onQueryConfigsChange: any
  chartDisplayConfig: IChartDisplayConfig
  onChartDisplayConfigChange: any
  timeRange: string
  onTimeRangeChange: any
  resetFunc: any
}

export default function BuilderPanel(props: BuilderPanelProps) {

  const { queryConfigs, onQueryConfigsChange,
          chartDisplayConfig, onChartDisplayConfigChange
         } = props

   const yAxisConfigChanged = (bound: string, val?: number ) => {
    const out = { ...chartDisplayConfig }
    switch (bound) {
      case 'y1Max':
        out.y1Max = val
        break
      case 'y1Min':
        out.y1Min = val
        break
      case 'y2Max':
        out.y2Max = val
        break
      case 'y2Min':
        out.y2Min = val
        break
      default:
        return
    }
    onChartDisplayConfigChange(out)
  }

  const chartTitleChanged = (e: any) => {
    if (e && e.target && 'value' in e.target) {
      const out = { ...chartDisplayConfig }
      out.title = e.target.value
      onChartDisplayConfigChange(out)
    }
  }

  const onQueryConfigChange = (cfg: IQueryConfig, i: number) => {
    const qcs = [ ...queryConfigs ] as IQueryConfig[]
    qcs[i] = cfg
    props.onQueryConfigsChange(qcs)
  }

  const addNewQuery = () => {
    const qcs = queryConfigs.concat(DefaultQueryConfig())
    onQueryConfigsChange(qcs)
  }

  const removeQueryConfig = (i: number) => {
    if (queryConfigs.length < i) {
      return
    }
    let qcs = [...queryConfigs]
    qcs = (qcs.filter((qc) => {
      return qc !== qcs[i]
    }))
    onQueryConfigsChange(qcs)
  }

  return (
    <BasicPanel className="chart-builder-panel filled">
      <ChartTitleInput onChange={chartTitleChanged} defaultValue={chartDisplayConfig.title} />
      <TimePicker defaultValue={props.timeRange}  timeChangeFunc={props.onTimeRangeChange} />
      <hr />
      {queryConfigs.map((cfg, i) => {
          return <QueryPane
            key={i}
            queryConfig={cfg}
            queryIndex={i}
            disableRemove={queryConfigs.length === 1}
            onRemove={removeQueryConfig}
            onQueryConfigChange={onQueryConfigChange}
          />
        })}
        <AddQueryLink onClick={addNewQuery} />
      <hr />
      <YAxisConfig onChange={yAxisConfigChanged} chartDisplayConfig={chartDisplayConfig} useY1={true} useY2={true} />
      <hr />
      <div className="reset-query-options-section">
        <ButtonBasic color="red" outline={true} label="Reset All Options" size="sm" onClick={props.resetFunc} />
      </div>
    </BasicPanel>
  )
}
