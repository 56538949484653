import { useContext, useEffect, useMemo } from "react"
import { FormDataLookup, FormElement, FormElementCheckBox, FormElementDataType, FormElementSelect, FormElementTextInput, FormElementType } from "../../components/forms/FormElement"
import { dayOfMonthOffsets, dayOfWeekOffsets, hourOffsets, minuteOffsets } from '../../utils/time/offests'
import { GeneralForm, SetCanSubmitFunc, SetFormDatafunc } from "../../components/forms/GeneralForm"
import { PrefsContext } from "../../utils/prefs/PrefsContext"
import { GetLocalizedTextItem } from "../../localization/localization"
import { reportCadences } from "./selectItems"
import { LabelValuePairs } from "../../types/generic"

interface GenerateConfigureScheduleProps {
  setFormDataFunc: SetFormDatafunc
  setCanContinueFunc: SetCanSubmitFunc
  formData: FormDataLookup
}


export function GenerateConfigureSchedule(props: GenerateConfigureScheduleProps) {

  const { language } = useContext(PrefsContext)
  const { formData, setCanContinueFunc } = props

  useEffect(() => {
    if (!('reportName' in formData) || !formData.reportName) {
      setCanContinueFunc(false)
      return
    }
    setCanContinueFunc(true)
  }, [formData, setCanContinueFunc])

  const isRecurring = useMemo((): boolean => {
    return 'generateRecurring' in formData && formData.generateRecurring === true
  }, [formData])

  const showDaysOfWeek = useMemo((): boolean => {
    return 'cadence' in formData && formData.cadence === 'weekly'
  }, [formData])

  const showDaysOfMonth = useMemo((): boolean => {
    return 'cadence' in formData && formData.cadence === 'monthly'
  }, [formData])

  const showHoursAndMinutes = useMemo((): boolean => {
    return 'cadence' in formData && typeof formData.cadence === 'string' &&
      ['weekly', 'daily', 'monthly'].includes(formData.cadence)
  }, [formData])

  const hoursList = useMemo((): LabelValuePairs => {
    const out = [] as LabelValuePairs
    for (const hour of hourOffsets) {
      out.push({value: hour.hour.toString(), label: hour.formatted})
    }
    return out
  }, [])

  const minutesList = useMemo((): LabelValuePairs => {
    const out = [] as LabelValuePairs
    for (const min of minuteOffsets) {
      out.push({value: min.minute.toString(), label: min.formatted.replaceAll(':', '')})
    }
    return out
  }, [])

  const daysOfMonthList = useMemo((): LabelValuePairs => {
    const out = [] as LabelValuePairs
    for (const day of dayOfMonthOffsets) {
      out.push({value: day.input.toString(), label: day.formatted})
    }
    return out
  }, [])

  const daysOfWeekList = useMemo((): LabelValuePairs => {
    const out = [] as LabelValuePairs
    for (const day of dayOfWeekOffsets) {
      out.push({value: day.input.toString(), label: day.formatted})
    }
    return out
  }, [])

  const formElements = useMemo((): FormElement[] => {
    const out = [
    {
      label: 'Report Name',
      elementType: FormElementType.TextInput,
      dataType: FormElementDataType.Text,
      value: true,
      fieldName: 'reportName',
      defaultValue: formData.reportName,
      wrapperClass: 'mt-2 ms-1 me-1',
      inputClass: 'wide',
      required: true
    } as FormElementTextInput,
    {
      elementType: FormElementType.Label,
      label: 'Schedule Settings',
      labelClass: 'generate-report-element-label mt-3'
    } as FormElement,
    {
      label: 'Generate This Report Now',
      elementType: FormElementType.Checkbox,
      dataType: FormElementDataType.Boolean,
      value: true,
      fieldName: 'generateNow',
      defaultChecked: 'generateNow' in formData && formData.generateNow === true,
      wrapperClass: 'mt-2'
    } as FormElementCheckBox,
    {
      label: 'Generate This Report on a Recurring Schedule',
      elementType: FormElementType.Checkbox,
      dataType: FormElementDataType.Boolean,
      value: true,
      fieldName: 'generateRecurring',
      defaultChecked: 'generateRecurring' in formData && formData.generateRecurring === true,
      wrapperClass: 'mt-2'
    } as FormElementCheckBox,
  ] as FormElement[]

  if (isRecurring) {
    out.push(
      {
        label: GetLocalizedTextItem(language, 'cadence', 'upperAllFirst'),
        elementType: FormElementType.Select,
        dataType: FormElementDataType.Text,
        fieldName: 'cadence',
        wrapperClass: 'mt-2',
        labelClass: 'width-95',
        items: reportCadences,
        defaultValue: formData.cadence
      } as FormElementSelect,
    )
  }

  if (isRecurring && showDaysOfMonth) {
    out.push(
      {
        label: GetLocalizedTextItem(language, 'day', 'upperAllFirst'),
        elementType: FormElementType.Select,
        dataType: FormElementDataType.Text,
        fieldName: 'cadenceDayOfMonth',
        labelClass: 'width-95',
        wrapperClass: 'mt-2',
        defaultValue: formData.cadenceDayOfMonth,
        items: daysOfMonthList
      } as FormElementSelect,
    )
  }

  if (isRecurring && showDaysOfWeek) {
    out.push(
      {
        label: GetLocalizedTextItem(language, 'day', 'upperAllFirst'),
        elementType: FormElementType.Select,
        dataType: FormElementDataType.Text,
        fieldName: 'cadenceDayOfWeek',
        labelClass: 'width-95',
        wrapperClass: 'mt-2',
        defaultValue: formData.cadenceDayOfWeek,
        items: daysOfWeekList
      } as FormElementSelect,
    )
  }

  if (isRecurring && showHoursAndMinutes) {
    out.push(
      {
        label: GetLocalizedTextItem(language, 'hour', 'upperAllFirst'),
        elementType: FormElementType.Select,
        dataType: FormElementDataType.Text,
        fieldName: 'cadenceHour',
        labelClass: 'width-95',
        wrapperClass: 'mt-2',
        defaultValue: formData.cadenceHour,
        items: hoursList
      } as FormElementSelect,
    )
    out.push(
      {
        label: GetLocalizedTextItem(language, 'minute', 'upperAllFirst'),
        elementType: FormElementType.Select,
        dataType: FormElementDataType.Text,
        fieldName: 'cadenceMinute',
        labelClass: 'width-95',
        wrapperClass: 'mt-2',
        defaultValue: formData.cadenceMinute,
        items: minutesList
      } as FormElementSelect,
    )
  }

  return out
  }, [formData, language, isRecurring, showDaysOfMonth, showDaysOfWeek, showHoursAndMinutes,
    daysOfMonthList, daysOfWeekList, hoursList, minutesList])

  return <div className="generate-configure-growers-report-form">
    <GeneralForm
      elements={formElements}
      setFormDatafunc={props.setFormDataFunc}
      setCanSubmitFunc={props.setCanContinueFunc}
    />
  </div>
}