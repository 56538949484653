import { useCallback, useContext, useEffect, useState } from 'react'

import { FlashMessageContext } from '../../components/notifications/FlashMessage/FlashMessageContext'

import ModalBasic, { StatusBanner } from '../../components/modals/ModalBasic'
import ButtonBasic from '../../components/Buttons/ButtonBasic'

import { faUserShield, faCheck, faCopy, faSignOutAlt, faRotate, faX } from '@fortawesome/free-solid-svg-icons'

import { accessToken, useAuth } from '../../utils/auth/provider'
import * as api from '../../utils/api/endpoints'
import { ApiKeyAccessInfo } from '../../types/api'
import { GeneralStatus } from '../../types/generic'

import './ModalUserInfo.css'

interface ModalUserInfoProps {
  closer?: any
}

export function ModalUserInfo(props: ModalUserInfoProps) {

  const [token, setToken] = useState(undefined as string | undefined)
  const [hasApiKeyAccess, setHasApiKeyAccess] = useState(false)
  const [isRotatingKey, setIsRotatingKey] = useState(false)
  const [newApiKey, setNewApiKey] = useState(undefined as string | undefined)
  const [mustPressCopy, setMustPressCopy] = useState(false)
  const [isRotationInProgress, setIsRotationInProgress] = useState(false)

  const { username, isAuthenticated, signOutFunc } = useAuth()
  const flash = useContext(FlashMessageContext)

  useEffect(() => {
    api.getApiKeyAccess()
    .then((resp: ApiKeyAccessInfo) => {
     if (resp && resp.email) {
      setHasApiKeyAccess(true)
     }
    })
  }, [setHasApiKeyAccess])

  useEffect(() => {
    accessToken()
    .then((t: string | null) => {
      if (t) {
        setToken(t)
      }
    })
  }, [])

  const email = username ? username : 'no signed in user'

  const copyAPiKey = useCallback(() => {
    if (newApiKey) {
      navigator.clipboard.writeText(newApiKey)
      flash.setFlashMessage('New API Key copied to Clipboard', 'ok', 3000, faCheck)
      setMustPressCopy(false)
    }
  }, [newApiKey, flash, setMustPressCopy])

  const rotateApiKey = useCallback(() => {
    setIsRotationInProgress(true)
    setMustPressCopy(true)
    api.rotateApiKey()
    .then((resp: any) => {
      if (resp && resp.data && resp.data.status && resp.data.status === 'ok' &&
        resp.data.newKey) {
        setNewApiKey(resp.data.newKey)
      } else {
        flash.setFlashMessage(`Failed to Rotate API Key. Please contact ${process.env.REACT_APP_appShortName} for assistance.`, 'error', 0, faX)
        setMustPressCopy(false)
      }
    })
    .catch(() => {
      flash.setFlashMessage(`Failed to Rotate API Key. Please contact ${process.env.REACT_APP_appShortName} for assistance.`, 'error', 0, faX)
      setMustPressCopy(false)
    })
    .finally(() => {
      setIsRotationInProgress(false)
    })
  }, [setNewApiKey, setMustPressCopy, flash])

  const handleSignOut = useCallback(() => {
    if (signOutFunc) {
      signOutFunc()
    }
  }, [signOutFunc])

  const copyToClipboard = useCallback(() => {
    if (isAuthenticated && token) {
      navigator.clipboard.writeText(token)
      flash.setFlashMessage('Token copied to Clipboard', 'ok', 3000, faCheck)
    }
  }, [flash, isAuthenticated, token])

  const footerButtons = useCallback(() => {
    if (isRotatingKey && !newApiKey) {
      return <>
        {hasApiKeyAccess &&
          <ButtonBasic
          label="Rotate API Key"
          onClick={() => {rotateApiKey()}}
          className="me-3"
          color="red"
          disabled={isRotationInProgress}
        />
        }
      </>
    }
    if (isRotatingKey && newApiKey) {
      return <>
          <ButtonBasic
          icon={faCopy}
          label="Copy New API Key to Clipboard"
          onClick={copyAPiKey}
          className="me-3"
        />
      </>
    }
    return (
      <>
        {hasApiKeyAccess &&
          <ButtonBasic
          icon={faRotate}
          label="Rotate API Key"
          onClick={() => {setIsRotatingKey(true)}}
          className="me-3"
        />
        }
        <ButtonBasic
          icon={faCopy}
          label="Copy Access Token"
          onClick={copyToClipboard}
          className="me-3"
        />
        <ButtonBasic
          icon={faSignOutAlt}
          label="Sign Out"
          onClick={handleSignOut}
          className="me-3"
        />
      </>
    )
  }, [hasApiKeyAccess, copyToClipboard, copyAPiKey, handleSignOut, isRotatingKey, setIsRotatingKey, newApiKey, rotateApiKey, isRotationInProgress])

  return (
    <div>
      <ModalBasic
        closer={props.closer}
        headerText={email}
        headerIcon={faUserShield}
        buttons={footerButtons}
        sz='smmd'
        closeable={!mustPressCopy}
      >
        <div className="modal-user-info">
        {hasApiKeyAccess && isRotatingKey && !newApiKey &&
          <>
          <StatusBanner
            label="Confirm API Key Rotation"
            status={GeneralStatus.Caution}
          />
          <hr />
          <div className="caution-content">
            <p>
              Rotating your API Key will immediately invalidate any existing Key assigned to you and generate a new one.
            </p>
            <p>
              Processes that already use your API Key must be reconfigured to use the new Key, else they will lose access to the {process.env.REACT_APP_appShortName} API.
            </p>
            <p>
              Press 'Rotate API Key' again to proceed.
            </p>
          </div>
          </>
        }
        {hasApiKeyAccess && isRotatingKey && newApiKey &&
          <>
          <StatusBanner
            label="API Key Rotation Successful"
            status={GeneralStatus.OK}
          />
          <hr />
          <div className="rotated-content">
            <p>
              Your API Key has been rotated. It may take up to 5 minutes for your new API Key to be accepted by the Agrology API.
            </p>
            <p>
              Once you press the Close button, you will not be able to retrieve the new API Key again. Save it to a secure location now.
            </p>
          </div>
          </>
        }
        {!isRotatingKey &&
          <>
          <StatusBanner
            label={`You are signed in to ${process.env.REACT_APP_appShortName}`}
            status={GeneralStatus.OK}
          />
          <hr />
          <div className="signed-in-content">
            <p>
              Return here any time in the future to retrieve your current Access Token{hasApiKeyAccess && <span>, rotate your API Key</span>} or Sign Out.
            </p>
            <p>
              Access Tokens{hasApiKeyAccess && <span> and API Keys</span>} can be used to <a href="https://github.com/agrology/public-api-docs" target="__new__">query the {process.env.REACT_APP_appShortName} API</a>.<br />Save yours in a secure place and never share with anyone else.{!hasApiKeyAccess && <>
                <br />Access Tokens expire after 1 hour.</>}
            </p>
            {hasApiKeyAccess &&
              <p>
              Access Tokens expire after 1 hour, while API Keys never expire. You can rotate your API Key whenever you'd like.
              </p>
            }
          </div>
          </>
        }
        </div>
      </ModalBasic>
    </div>
  )
}

export default ModalUserInfo
