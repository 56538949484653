import { LabelValuePairs } from "../../../types/generic"
import { RadioGroup } from "../../../components/Radios/RadioGroup"

import './LineStyleConfig.css'

interface LineStyleConfigProps {
  labelClass?: string
  defaultValue?: string
  onChange?: any
  queryIndex?: number
}

export default function LineStyleConfig(props: LineStyleConfigProps) {

  const vals =
  [
    {
      label: "Lines",
      value: "line"
    },
    {
      label: "Bars",
      value: "bar"
    }
  ] as LabelValuePairs

  return (
    <div className="line-style-config">
      <RadioGroup
        label="Style"
        labelClass={props.labelClass}
        items={vals}
        defaultValue={props.defaultValue}
        groupName={"lineStyleConfig-" + props.queryIndex}
        onClick={props.onChange}
      />
    </div>
  )

}
