import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { PrefsContext } from '../../../utils/prefs/PrefsContext'
import { getLibraryChartsByCustomer, updateLibraryChart } from '../../../utils/api/endpoints'
import { ApiStep, LibraryChart } from '../../../types/api'
import { GeneralStatus } from '../../../types/generic'

import ModalBasic, { ButtonConfig, StatusBanner } from '../../../components/modals/ModalBasic'
import { faChartArea } from '@fortawesome/free-solid-svg-icons'

import FolderSelector from './FolderSelector'
import TextInputRow from '../../../components/forms/TextInputRow'

import './ModalRelocateLibraryChart.css'

interface ModalRelocateLibraryChartProps {
  closer?: any
  customerDN: string | undefined
  libraryChart: LibraryChart
}

export function ModalRelocateLibraryChart(props: ModalRelocateLibraryChartProps) {

  const [closeRequested, setCloseRequested] = useState(false)
  const [step, setStep] = useState(ApiStep.Confirm)
  const { libraryChart, customerDN } = props
  const [folder, setFolder] = useState(libraryChart.folder as string)
  const [newFolder, setNewFolder] = useState('')
  const [chartDesc, setChartDesc] = useState(libraryChart.description as string)
  const [charts, setCharts] = useState([] as LibraryChart[])
  const  { customer } = useContext(PrefsContext)

  useEffect(() => {
    getLibraryChartsByCustomer(customer)
    .then((resp) => {
      if (resp && resp.length > 0) {
        setCharts(resp)
      }
    })
  }, [setCharts, customer])

  const nameTaken = useMemo((): boolean  => {
    if (!chartDesc) {
      return false
    }
    for (const chart of charts) {
      if (chart.description.trim() === chartDesc.trim() && chart.id !== libraryChart.id) {
        return true
      }
    }
    return false
  }, [charts, libraryChart, chartDesc])

  const canUpdate = useMemo(() => {
    return folder !== '' && chartDesc !== '' && (folder !== libraryChart.folder || chartDesc !== libraryChart.description)
  }, [libraryChart, folder, chartDesc])

  const folderChanged = (e: any) => {
    if (e && e.target && e.target.value) {
      setFolder(e.target.value)
    }
  }

  const newFolderChanged = (input: string) => {
    setNewFolder(input)
  }

  const moveChart = useCallback(() => {
    if (!libraryChart || !canUpdate) {
      console.log('no customer selected, not saving shart')
      return
    }
    console.log(">>>>", newFolder, folder)
    const data = {
      folder: newFolder || folder,
      description: chartDesc
    } as any

    setStep(ApiStep.InProgress)
    updateLibraryChart(libraryChart.customerID, libraryChart.id, data)
    .then(() => {
      setStep(ApiStep.Done)
      setTimeout(() => {setCloseRequested(true)}, 1500)
    })
    .catch((e) => {
      setStep(ApiStep.Error)
      console.log('chart relocation failed', e)
    })
  }, [libraryChart, canUpdate, setStep, setCloseRequested, folder, newFolder, chartDesc])

  const buttonConfigs = useMemo(() => {
    return (
      [{
        title: 'Update Chart',
        disabled: step !== ApiStep.Confirm || !canUpdate || nameTaken,
        onClick: moveChart,
        className: 'me-3',
      }] as ButtonConfig[]
    )
  }, [step, moveChart, canUpdate, nameTaken])

  return (
    <ModalBasic
      closer={props.closer}
      closeLabel={step === ApiStep.Confirm ? "Cancel" : "Close"}
      headerText="Move / Rename Chart"
      headerIcon={faChartArea}
      sz="sm"
      buttonConfigs={buttonConfigs}
      closeRequested={closeRequested}
      closeable={step !== ApiStep.InProgress}
    >
      <div className="modal-relocate-chart">
        <div className="modal-relocate-chart-body">

          {step === ApiStep.Confirm && <>
            <TextInputRow
              label="Chart Name"
              labelClass="width-95"
              onChange={setChartDesc}
              defaultValue={chartDesc}
              className="chart-relocate-description"
            />
            <FolderSelector
              label="Folder"
              labelClass="width-95"
              defaultValue={folder}
              onChange={folderChanged}
              charts={charts}
              showAddNew={true}
            />
            {folder === 'new' &&
              <TextInputRow
                label="New Folder"
                labelClass="width-95"
                onChange={newFolderChanged}
              />
            }
            <div className="centered mt-4">
              Updates will affect all users at {customerDN}.
            </div>

            {nameTaken && <>
            <hr className="mt-3" />
            <div className="modal-relocate-name-conflict">
              There is already a Chart in your library named {chartDesc}. You must use a different name.
            </div>
            </>
            }

          </>}

          {step === ApiStep.InProgress &&
          <StatusBanner
            isLarge={true}
            label="Working..."
            className="chart-relocate-in-progress"
            status={GeneralStatus.Muted}
          />}

          {step === ApiStep.Done &&
          <StatusBanner
            isLarge={true}
            label="Chart Successfully Updated"
            status={GeneralStatus.OK}
          />}

          {step === ApiStep.Error && <>
            <StatusBanner
              isLarge={true}
              label="Chart Update Failed"
              status={GeneralStatus.Failure}
            />
            <div className="centered">Your Chart was not updated due to a system error. We're really sorry about this! Please contact Agrology for further assistance.</div>
          </>}

        </div>
      </div>
    </ModalBasic>
  )
}