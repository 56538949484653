import Map, {NavigationControl, ScaleControl} from 'react-map-gl/mapbox'
import 'mapbox-gl/dist/mapbox-gl.css'

import { MAPBOX_LICENSE } from '../../app/app'


export function Testing() {
  return <>
    <div className="content-pane" style={{marginLeft: '210px'}}>
      <Map
        reuseMaps
        attributionControl={false}
        mapboxAccessToken={MAPBOX_LICENSE}
        initialViewState={{
          longitude: -122.4,
          latitude: 37.8,
          zoom: 14
        }}
        style={{width: 1000, height: 'calc(100vh - 120px)'}}
        mapStyle="mapbox://styles/mapbox/streets-v9"
      >
        <NavigationControl visualizePitch={false} showCompass={false} />
        <ScaleControl position="bottom-right" />
        </Map>
    </div>
  </>
}