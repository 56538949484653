import { useContext, useEffect, useMemo } from 'react'
import { PrefsContext } from '../../utils/prefs/PrefsContext'
import { ReportDefinition, ReportRun, ReportDefinitionLookup } from '../../types/api'
import { headerColumn, row, rowCell } from '../../components/Tables/tables'
import { ago } from '../../utils/time/relativeTime'
import { runStatusLabel } from './schedule'
import { NoParamsFunc } from "../../types/generic"
import BasicTable from '../../components/Tables/BasicTable'

import './RecentReportsPane.css'
import { faCog, faFileLines } from '@fortawesome/free-solid-svg-icons'

export type OpenEditorFunc = (rd: ReportDefinition) => void
export type OpenRunViewerFunc = (run: ReportRun) => void

interface RecentReportsPaneProps {
  defs?: ReportDefinition[]
  runs?: ReportRun[]
  isLoading?: boolean
  requestReloadFunc: NoParamsFunc
  openEditorFunc: OpenEditorFunc
  openRunViewerFunc: OpenRunViewerFunc
}

const runStatusClass = (run: ReportRun): string => {
  if (run.status === 'success') {
    return 'report-run-success'
  }
  if (run.status === 'fail') {
    return 'report-run-failed'
  }
  return ''
}

export function RecentReportsPane(props: RecentReportsPaneProps) {

  const { customerAccess } = useContext(PrefsContext)
  const { defs, runs, isLoading, openEditorFunc, openRunViewerFunc, requestReloadFunc } = props

  const customerDN = useMemo((): string => {
    if (!customerAccess || !customerAccess.displayName) {
      return 'Unknown Organization'
    }
    return customerAccess.displayName
  }, [customerAccess])

  const cols = [
    { text: 'Report Name' },
    { text: 'Time' },
    { text: 'Format' },
    { text: 'Status' },
    { text: '# Files' },
  ] as headerColumn[]


  const hasRunningReports = useMemo((): boolean => {
    if (!runs || runs.length === 0) {
      return false
    }
    for (const run of runs) {
      if (run && (['new', 'running'].includes(run.status || ''))) {
        return true
      }
    }
      return false
  }, [runs])

  useEffect(() => {
    const interval = setInterval(() => {
      if (hasRunningReports) { requestReloadFunc() }
    }, 3000);
    return () => clearInterval(interval);
  }, [hasRunningReports, requestReloadFunc]);

  const defsLookup = useMemo((): ReportDefinitionLookup => {
    const out = {} as ReportDefinitionLookup
    if (!defs) {
      return out
    }
    for (const def of defs) {
      out[def.id] = def
    }
    return out
  }, [defs])

  const runsWithDef = useMemo((): ReportRun[] => {
    const out = [] as ReportRun[]
    if (!runs || runs.length === 0) {
      return out
    }
    for (const run of runs) {
      if (run.reportDefinitionID in defsLookup) {
        run.def = defsLookup[run.reportDefinitionID]
        out.push(run)
      }
    }
    return out
  }, [runs, defsLookup])

  const sortedRuns = useMemo((): ReportRun[]  => {
    const out = [] as ReportRun[]
    if (!runsWithDef || runsWithDef.length === 0) {
      return out
    }
    for (let i = 0; i < runsWithDef.length; i++) {
      out.push(runsWithDef[i])
    }
    const compare = (a: ReportRun, b: ReportRun ): number => {
      if ((a.ts || 0) > (b.ts || 0)){
        return -1
      }
      if ((a.ts || 0) < (b.ts || 0)){
        return 1
      }
      return 0
    }
    out.sort(compare)
    if (out.length <= 10) {
      return out
    }
    return out.slice(0, 10)
  }, [runsWithDef])

  const rows = useMemo((): row[] => {
    const out = [] as row[]
    if (sortedRuns.length === 0) {
      return out
    }
    for (var i = 0; i < sortedRuns.length; i++) {
      if (!sortedRuns[i]) {
        continue
      }
      const r = sortedRuns[i]
      out.push({
        cells: [
          {
            iconRight: faCog,
            value: r.def ? r.def.description || '-' : '-',
            onClick: r.def ? () => { openEditorFunc(r.def ? r.def : {} as ReportDefinition) } : undefined,
          },
          {
            iconRight: faFileLines,
            value: ago(sortedRuns[i].ts || 0),
            onClick: () => { openRunViewerFunc(r) },
          },
          {
            value:  (r.def?.reportFormat || '').toUpperCase()
          },
          {
            value: runStatusLabel(sortedRuns[i]),
            className: runStatusClass(sortedRuns[i])
          },
          {
            value: !sortedRuns[i].files ? 0 : sortedRuns[i].files.length
          }
        ] as rowCell
      } as row)
    }
    return out
  }, [sortedRuns, openEditorFunc, openRunViewerFunc])

  return <div className="reports-recently-generated-list">
    <BasicTable
      cols={cols}
      rows={rows}
      isLoading={isLoading && (!rows || rows.length === 0)}
      noDataMessage={`${customerDN} has no reports. Press '+ New Report' to generate one.`}
    />
  </div>
}