import { useContext, useMemo } from "react"
import { PrefsContext } from "../../utils/prefs/PrefsContext"
import { siteAndNodeOptions as  getSiteAndNodeOptions, SiteAndNodeOptions,
  SiteAndNodeOptionsInput} from '../../components/fleet/SiteAndNodeOptions'
import { GeneralForm, SetCanSubmitFunc, SetFormDatafunc } from '../../components/forms/GeneralForm'
import { FormDataLookup, FormElementDataType, FormElements, FormElementSelect, FormElementTimePicker, FormElementType } from "../../components/forms/FormElement"
import { csvReportFilesScopes, reportRanges, timeAggregations } from "./selectItems"
import { GetLocalizedTextItem } from "../../localization/localization"

interface GenerateConfigureCSVProps {
  setFormDataFunc: SetFormDatafunc
  setCanContinueFunc: SetCanSubmitFunc
  formData: FormDataLookup
}

export function GenerateConfigureCSV(props: GenerateConfigureCSVProps) {
  const { customerAccess, language } = useContext(PrefsContext)
  const { formData } = props
  const { siteId } = formData

  const siteAndNodeOptions = useMemo((): SiteAndNodeOptions => {
    return getSiteAndNodeOptions({
      customerAccess: customerAccess,
      selectedSite: siteId,
      showAllSitesOption: true,
      showAllNodesOption: true,
    } as SiteAndNodeOptionsInput)
  }, [customerAccess, siteId])

  const formElements = useMemo(() => {
    const out = [
      {
        elementType: FormElementType.Select,
        dataType: FormElementDataType.Text,
        label: 'Site',
        fieldName: 'siteId',
        items: siteAndNodeOptions.sites,
        defaultValue: formData.siteId
      } as FormElementSelect,
      {
        elementType: FormElementType.Select,
        dataType: FormElementDataType.Text,
        label: 'Node',
        fieldName: 'nodeId',
        items: siteAndNodeOptions.nodes,
        disabled: !siteAndNodeOptions.selectedSite ||
          siteAndNodeOptions.selectedSite === 'all',
        defaultValue: formData.nodeId
      } as FormElementSelect,
      {
        elementType: FormElementType.Select,
        dataType: FormElementDataType.Text,
        label: 'Time Period',
        fieldName: 'timePeriod',
        items: reportRanges,
        defaultValue: formData.timePeriod
      } as FormElementSelect
    ] as FormElements

    if (formData && 'timePeriod' in formData && formData.timePeriod === 'custom') {
      out.push({
        elementType: FormElementType.TimePicker,
        label: 'Time Period',
        fieldName: 'timeRange',
        timeRange: formData.timeRange
      } as FormElementTimePicker)
    }

    out.push({
      elementType: FormElementType.Select,
      dataType: FormElementDataType.Text,
      label: 'Time Buckets',
      fieldName: 'timeBucket',
      items: timeAggregations,
      defaultValue: formData.timeBucket
    } as FormElementSelect)

    out.push({
      elementType: FormElementType.Select,
      dataType: FormElementDataType.Text,
      label: GetLocalizedTextItem(language, 'fileCount', 'upperAllFirst'),
      fieldName: 'fileCount',
      items: csvReportFilesScopes,
      defaultValue: formData.fileCount
    } as FormElementSelect)

    return out
  }, [formData, siteAndNodeOptions, language])

  return <div className="generate-configure-csv-form">
    <div className="generate-report-section-header">
      CSV Report Options
    </div>
    <div className="generate-report-section-content">
      <GeneralForm
        elements={formElements}
        labelClass="width-95"
        wrapperClass="mb-2"
        inputClass="wide"
        setFormDatafunc={props.setFormDataFunc}
        setCanSubmitFunc={props.setCanContinueFunc}
      />
    </div>
  </div>
}